export default class Time {
    public static getCurrentDateTime(): string {
        const now: Date = new Date();
        const year: number = now.getFullYear();
        const month: string = this.padZero(now.getMonth() + 1);
        const day: string = this.padZero(now.getDate());
        const hour: string = this.padZero(now.getHours());
        const minute: string = this.padZero(now.getMinutes());
        const second: string = this.padZero(now.getSeconds());
      
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }

    public static getCurrentTime = () => {
        const today = new Date();
        return today.getTime();
    }
      
    private static padZero(value: number): string {
        return value.toString().padStart(2, '0');
    }

    public static convertDatetimeFormat(datetimeString: string) {
        const date = new Date(datetimeString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

  public static convertDateFormat(datetimeString: string) {
    const date = new Date(datetimeString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
    
  public static epochToDate(epochTime: string) {
    const date = new Date(epochTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  public static getTimeSinceDate(dateString: string): string {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    let yearDiff = currentDate.getFullYear() - pastDate.getFullYear();
    let monthDiff = currentDate.getMonth() - pastDate.getMonth();
    let weekDiff = Math.floor((currentDate.getTime() - pastDate.getTime()) / (1000 * 60 * 60 * 24 * 7));

    // Adjust differences if needed
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < pastDate.getDate())) {
        yearDiff--;
        monthDiff += 12; // Borrow 12 months from year
    }
    if (currentDate.getDate() < pastDate.getDate()) {
        monthDiff--;
        // Weeks are recalculated from the month difference
        weekDiff = Math.floor((currentDate.getTime() - new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, pastDate.getDate()).getTime()) / (1000 * 60 * 60 * 24 * 7));
    }

    // Pluralization helper
    const pluralize = (count: number, noun: string) => `${count} ${noun}${count !== 1 ? 's' : ''}`;

    // Construct the time difference string
    let timeDiffString = '';
    if (yearDiff > 0) timeDiffString += pluralize(yearDiff, 'year') + ' ';
    if (monthDiff > 0) timeDiffString += pluralize(monthDiff, 'month') + ' ';
    if (weekDiff > 0 && yearDiff === 0) timeDiffString += pluralize(weekDiff, 'week') + ' ';

    return timeDiffString.trim() || '0 days';
  }

}