import { useState } from 'react';
import ServerRequest from '../ServerRequest';

// Types
import { TCharacter } from '@shared/types/Character';

export interface IUseCharacters {
    get: TCharacter[];
    set: (T: any) => void;
    add: (T: TCharacter) => void;
    remove: (T: number) => void;
    setEnabled: (characterId: number, value: boolean) => void;
    setField: <K extends keyof TCharacter>(characterId: number, key: K, value: TCharacter[K]) => void;
    refresh: () => void;
    fill: (T:TCharacter[]) => void;
    getCharactersByUserId: (T: number | undefined) => TCharacter[];
}

export default function useCharacters(): IUseCharacters {
    const [ characters, setCharacters ] = useState<TCharacter[]>([]);

    async function refresh() {
        setCharacters((await ServerRequest.get('characters')).data.data);
    }

    function getCharactersByUserId(userId: number | undefined) {
        return characters.filter(c => c.user_id === userId);
    }

    function fill(data: TCharacter[]) {
        setCharacters(data);
    }

    function add(character: TCharacter) {
        setCharacters(prev => ([...prev, character]))
    }

    function remove(characterId: number) {
        setCharacters(prev => prev.filter(c => c.id !== characterId));
    }

    function setEnabled(characterId: number, value: boolean) {
        setCharacters(prev => prev.map(c => {
            if (c.id === characterId) {
                c.is_enabled = value   
            }

            return c;
        }));
    }

    function setField<K extends keyof TCharacter>(characterId: number, key: K, value: TCharacter[K]) {
        setCharacters(prev => prev.map(c => {
            if (c.id === characterId) {
                c[key] = value; 
            }
            return c;
        }));
    }

    return {
        get: characters,
        set: setCharacters,
        add,
        remove,
        setEnabled,
        setField,
        refresh: refresh,
        fill,
        getCharactersByUserId
    }
}