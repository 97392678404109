import { useContext } from 'react';

// Utils
import Time from '../../../../utils/time';

// Contexts
import { DynamicDataContext } from '../../../../api/DynamicData';

// Components
import Spinner from '../../../../components/common/AnimatedIcon/Spinner';

export default function GeneralInfo() {
    const { realm } = useContext(DynamicDataContext);

    const {
        name,
        address,
        founding_date,
    } = realm.get ?? {};

    return (
        <section className='GeneralInfo '>
            <div className='col'>
                <div className='title name'>
                    {name ? <strong>{name}</strong> : <Spinner />}
                </div>

                <div className='under-title'>
                    {address ? address : <Spinner />}
                </div>
            </div>
            <div className='col'>
                <div className='title'>
                    {founding_date 
                        ? `Founded: ${Time.epochToDate(founding_date)}`
                        : <Spinner />
                    }
                </div>

                <div className='under-title'>
                    {founding_date
                        ? Time.getTimeSinceDate(Time.convertDatetimeFormat(founding_date))
                        : <Spinner />
                    }
                </div>
            </div>
        </section>
    )
}   