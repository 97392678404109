
interface ITextInputProps {
    changeEvent: (T: any) => any;
    placeholder: string;
}

export default function TextInput({ changeEvent, placeholder }: ITextInputProps) {

    return (
        <input className='Input'
            onChange={changeEvent}
            placeholder={placeholder}
        />
    )
}