import { useState } from 'react';

// Types
import { TDiningItem } from '@shared/types/DiningItem';

export interface IUseDiningMenu {
    get: TDiningItem[];
    add: (T: TDiningItem) => void;
    set: React.Dispatch<React.SetStateAction<TDiningItem[]>>
    remove: (T: number) => void;
    fill: (T: TDiningItem[]) => void;
}

export default function useDiningMenu(): IUseDiningMenu {
    const [ diningItems, setDiningItems ] = useState<TDiningItem[]>([]);

    function fill(data: TDiningItem[]) {
        setDiningItems(data);
    }

    function add(item: TDiningItem) {
        setDiningItems(prev => ([...prev, item]))
    }

    function remove(itemId: number) {
        setDiningItems(prev => prev.filter(i => i.id !== itemId));
    }

    return {
        get: diningItems,
        set: setDiningItems,
        add,
        remove,
        fill,
    }
}