export default function Toggle({ value, clickEvent, isDisabled = false }) {

    return (
        <label className={`Toggle${isDisabled ? ' inactive' : ''}`}>
            <input 
                type='checkbox' 
                readOnly 
                checked={value}
                onClick={clickEvent} />
            <span className='slider' />
        </label>
    )
}