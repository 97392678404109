


interface IButtonProps {
    children: any;
    type?: 'constructive' | 'destructive' | 'progressive' | 'neutral' | 'dark' | 'inactive';
    clickEvent?: any;
    handleHover?: any;
    inactive?: boolean;
    className?: string;
}

export default function Button({
    children, 
    type, 
    clickEvent, 
    handleHover,
    inactive,
    className
}: IButtonProps) {

    function handleClick() {
        clickEvent();
    }

    return (
        <button 
            className={`Button ${type} ${inactive ? inactive : ''} ${className ? className : ''}`} 
            onClick={handleClick}
            onMouseOver={() => {handleHover && handleHover(true)}}
            onMouseLeave={() => {handleHover && handleHover(false)}}
        >
            {
                children
            }
        </button>
    )
}