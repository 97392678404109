import Axios from "axios";
import Cookies from "./Cookies";

export default class ServerRequest {

    private static readonly url = process.env.NODE_ENV === "development" 
    ? 'http://localhost:3001' 
    : `https://${document.location.hostname}`;

    public static tryLogin(username: string, password: string, realm: string): void {
        Axios.post(this.url + "/login", {
            username: username,
            password: password,
            realm: realm,
            loginToken: Cookies.getLoginToken()
        }).then(res => {
            const { isSuccessful, message, cookie } = res.data;

            if(isSuccessful) {
                Cookies.setCookie(cookie);
                window.location.href = this.url + "/dashboard";
            } else {
                window.alert(message);
            } 
        });
    }

    public static logout() {
        const token_id = Cookies.getLoginToken();
        Axios.post(this.url + '/logout', {
            token_id: token_id
        }).then(res => {
            const { isSuccessful, message } = res.data;

            if(isSuccessful) {
                window.location.href = this.url + "/login";
            } else {
                console.log(res)
                window.alert(message);
            } 
        })
    }

    public static async get(table: string) {
        const token_id = Cookies.getLoginToken();
        const result = await Axios.post(this.url + "/get", {
            token_id: token_id,
            table: table
        });

        return result;
    }

    public static async post(endpoint: string, data?: Object) {
        const token_id = Cookies.getLoginToken();
    
        try {
            const result = await Axios.post(`${this.url}/${endpoint}`, {
                token_id: token_id,
                ...data
            });

            return result;
        } catch (error: any) {
            console.error('Error:', error.response ? error.response : error);
            return error.response ? error.response : error;
        }
    }

    public static async getEnvironment() {
        const result = await Axios.get(this.url + "/environment");

        return result;
    }

    public static uploadImage(
        image: File, 
        type: string, 
        id: string, 
        messages?: any
    ):any {
        return new Promise((resolve) => {
            const formData = new FormData();
            formData.append('image', image);
            formData.append('id', id); 
            formData.append('type', type);
            formData.append('loginToken', Cookies.getLoginToken())
    
            Axios.post(this.url + "/upload_image", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                resolve({
                    status: res.status,
                    data: res.data,
                });
            });
        })
    }
}