
import { useContext } from 'react';

// Contexts
import { UIContext } from '../../../../../../../../../api/UI';

// Modals
import ConfirmModal from './../../../../../../../../../components/common/Modal/ConfirmModal';
import ContentModal from './../../../../../../../../../components/common/Modal/ContentModal'
import PasswordResetConfirmationModal from '../PasswordResetConfirmationModal/PasswordResetConfirmationModal';

// Types
import { type TUser } from '@shared/types/User';

// Utils
import ServerRequest from '../../../../../../../../../ServerRequest';

interface IResetPasswordModalProps {
    id?: number;
    user: TUser;
}

export default function ResetPasswordModal({
    id,
    user,
}: IResetPasswordModalProps) {

    const { modals, notifications } = useContext(UIContext);

    async function handleResetPassword(user: TUser | undefined) {
        if (!user) return;

        const result = await ServerRequest.post('reset_password', {user_id: user.id});

        if (result.status === 200) {
            modals.add(
                <ContentModal headline='Password Reset'>
                    <PasswordResetConfirmationModal 
                        username={user.username}
                        password={result.data.newPassword}
                    />
                </ContentModal>
            )
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            })
        }
    }

    return (
        <ConfirmModal 
            id={id}
            headline={`Password Reset`}
            message={`Reset the password of ${user.username}?`}
            callback={() => {handleResetPassword(user)}}
        />
    )
}