import kiwiImage from '../../assets/images/kiwi.jpg';

export default function About() {
    return (
        <div className='About'>
            <div className='wrapper'>
                <header>
                    <div className='title'>Kiwi & Coconut</div>
                    <img src={kiwiImage} alt='Looking out for kiwi.'/>
                    "Kiwi" and its software module "Coconut" are independently designed and developed applications intended for use with fictional 
                    venues and roleplaying events within Final Fantasy XIV: A Realm Reborn. They provide a simulation framework for managing 
                    restaurant- or bar-type venues, complete with tools for customer and order management, in-game currency handling, and staff 
                    salary administration.
                    <br/><br/>
                    These applications are fan-made tools and are <u>not</u> officially affiliated with, endorsed, or sponsored by Square Enix or its 
                    licensors. "Final Fantasy XIV: A Realm Reborn" and all related trademarks belong to their respective owners.
                    <br/><br/>
                    All venue names, customer names, and menu items featured within the applications are entirely fictional. Any resemblance to actual 
                    organizations, persons, or brands is purely coincidental and not intended.
                </header>

                <section id='development'>
                    <div className='title'>Development</div>
                    <div className='list'>
                        <div className='row'>
                            <span>Front-End & UI Design:</span>
                            <span className='name'>Enhasa</span>
                        </div>
                        <div className='row'>
                            <span>Back-End & APIs:</span>
                            <span className='name'>Enhasa</span>
                        </div>
                        <div className='row'>
                            <span>Database Design & Management:</span>
                            <span className='name'>Enhasa</span>
                        </div>
                    </div>
                </section>

                <section id='contributions'>
                    <div className='title'>Core Contributions</div>
                    <div className='list'>
                        <div className='row'>
                            <span>QA Testing:</span>
                            <span className='name'>Sokky</span>
                        </div>
                        <div className='row'>
                            <span>QA Testing:</span>
                            <span className='name'>Ripa</span>
                        </div>
                        <div className='row'>
                            <span>QA Testing:</span>
                            <span className='name'>Octavia</span>
                        </div>
                        <div className='row'>
                            <span>QA Testing:</span>
                            <span className='name'>Nessie Bun</span>
                        </div>
                    </div>
                </section>

                <section id='thanks'>
                    <div className='title'>Special Thanks</div>
                    I am grateful for all the input from everyone interested in this project, but I want to extend my sincerest <span className='highlighted'>thank you</span> to all of the QA Testers for their 
                    rigorous and unrelenting dedication in helping me solve issues and complete features. This project has taken many months and countless late nights to build,
                    and seriously wouldn't be feasable without the selfless support from these amazing people. ♥
                    <br/><br/>
                    I would also like to extend my thanks to the following people:
                    <ul>
                        <li>
                            Welgar, for the assistance in SQL related questions.
                        </li>
                        <li>
                            Yoorana, for fixing a front-end issue.
                        </li>

                    </ul>
                </section>
            </div>
        </div>
    )
}