import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Pages
import Login from './pages/Login/Login';
import About from './pages/About/About';
import Tools from './pages/Tools/Tools';
import Dashboard from './pages/Dashboard/Dashboard';
import Legal from './pages/Legal/Legal';

// Tools
import Cookies from './Cookies';

// Contexts
import { DynamicDataContextProvider } from './api/DynamicData';
import { UIContextProvider } from './api/UI';

function App() {

  useEffect(() => {
    Cookies.init();
  }, []);

  return (
    <UIContextProvider>
      <DynamicDataContextProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Dashboard />} path="/" />
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<Login />} path="/login" />
            <Route element={<About />} path="/about" />
            <Route element={<Legal />} path='/legal' />
            <Route element={<Tools />} path="/tools" />
          </Routes>
        </BrowserRouter>
      </DynamicDataContextProvider>
    </UIContextProvider>
  );
}

export default App;
