import { useState } from "react";

import Users from "./components/Users/Users";
import Characters from "./components/Characters/Characters";

import MultiToggle from "./../../../../../../components/common/MultiToggle/MultiToggle";
import MultiToggleOption from "./../../../../../../components/common/MultiToggle/MultiToggleOption";

// Utils
import uuid from "react-uuid";


export default function Staff() {
    const [ selectedOption, setSelectedOption ] = useState(0);
    const [ key, setKey ] = useState(uuid());

    function refresh() {
        setKey(uuid());
    }

    const options = [
        {name: 'Characters', component: <Characters key={key} refresh={refresh}/>},
        {name: 'Users', component: <Users key={key} refresh={refresh}/>},
    ];
    
    return (
        <div className='Staff'>            
            {options[1].component}
        </div>
    )
}