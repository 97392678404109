import { useContext } from 'react';

// Contexts
import { DynamicDataContext } from '../../../../../../api/DynamicData';
import { UIContext } from '../../../../../../api/UI';

// Components
import Button from '../../../../../../components/common/Button/Button';
import SafetyChangeModal from '../../../../../../components/common/Modal/SafetyChangeModal';
import ServerRequest from '../../../../../../ServerRequest';

export default function Account() {
    const { user } = useContext(DynamicDataContext);
    const { modals, notifications } = useContext(UIContext);

    async function changePassword(newPassword: string) {
        const result = await ServerRequest.post('update_user', {
            data: {
                user_id: user.get?.id,
                fieldsToUpdate: [['password', newPassword]]
            }
        })

        if (result.status !== 200) {
            notifications.add({
                title: 'Error',
                type: 'error',
                content: 'Failed to update password.'
            })
        } else {
            notifications.add({
                title: 'Password changed',
                type: 'confirmation',
                content: 'Your password has been changed. You will be logged out shortly.'
            });

            setTimeout(() => {
                const baseUrl = window.location.origin;
                const url = `${baseUrl}/login`;
        
                window.location.href = url;
            }, 2500);
        }
    }

    function handleChangePasswordModal() {
        modals.add(<SafetyChangeModal 
            headline='Change Password'
            toChange='password'
            message="Password must be at least one character. Yeah that's it."
            confirmMessage='Confirm & Logout'
            isEmptyAllowed={false}
            inputType='password'
            callback={changePassword}
        />);
    }

    return (
        <div className='Account'>
            <div className='container'>
                <div className='item username'>
                    <span className='label'>Username:</span>
                    <span className='value'>{user.get?.username}</span>
                </div>
                <div className='item password'>
                    <span className='label'>Password:</span>
                    <span className='value'>
                        <Button type={'neutral'} clickEvent={handleChangePasswordModal}>Change</Button>
                    </span>
                </div>
            </div>
            <div className='info'>
                You will be logged out after changing either of these settings.
            </div>
        </div>
    )
}