import { useState } from 'react';
import ServerRequest from '../../ServerRequest';
import EnvironmentNotice from '../../components/EnvironmentNotice/EnvironmentNotice';
import CookieBanner from './../../components/common/CookieBanner/CookieBanner';

export default function Login() {

    const [ realm, setRealm ] = useState("");
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");

    function handleSubmit(e?:React.MouseEvent|React.KeyboardEvent|React.FormEvent):void {
        e && e.preventDefault();
        ServerRequest.tryLogin(username, password, realm);
    }

    function handleRealm(value: string) {
        setRealm(value); 
    }
    function handleUsername(value:string) {
        setUsername(value);
    }
    function handlePassword(value:string) {
        setPassword(value);
    }

    function handleEnter(e: React.KeyboardEvent): void {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    }

    return (
        <div className='login-wrapper'>
            <EnvironmentNotice />
            <CookieBanner />
            <div className="Login">
                <div className='background'>
                    <video autoPlay loop muted>
                        <source src='videos/kiwi_compressed.mp4' type='video/mp4' />
                    </video>
                </div>

                <div className='login-box'>
                    <div className="form-container">
                        <div className="header">
                            Kiwi Login
                        </div>

                        <form onSubmit={handleSubmit}>
                            <label htmlFor="frealm">
                                <span>Realm</span>
                                <span 
                                    className="tooltip">
                                    <span className="tooltip">What's this?</span>

                                    <span className="tooltiptext">
                                        The realm alias provided by your venue administrator.
                                    </span>
                                
                                </span>
                            </label>

                            <input 
                                type="text" 
                                placeholder="Realm" 
                                id="frealm" 
                                name="realm" 
                                onChange={e => handleRealm(e.target.value)}
                                onKeyDown={handleEnter}
                            />

                            <label htmlFor="fusername">Username</label>
                            <input 
                                type="text" 
                                placeholder="Username" 
                                id="fusername" 
                                name="username" 
                                onChange={e => handleUsername(e.target.value)}
                                onKeyDown={handleEnter}
                            />

                            <label htmlFor="fpassword">Password</label>
                            <input 
                                type="password" 
                                placeholder="*********" 
                                id="fpassword" 
                                name="password" 
                                onChange={e => handlePassword(e.target.value)}
                                onKeyDown={handleEnter}
                            />

                            <button type="submit" value="Login" onClick={handleSubmit} className='Button constructive'>Login</button>
                        </form>

                        <div className="footer">
                            Kiwi | Venue Management | by Enhasa
                        </div>
                    </div>
                </div>
            </div>
            <div className='video-info'>
                This video is not property of littlekiwi. All credits go to the author. <a href='https://www.youtube.com/watch?v=Y0fbYuvSIY0' target='_blank' rel='noreferrer' >Original video.</a>
            </div>
        </div>
    );
}