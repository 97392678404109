import { useState, useContext, useEffect } from 'react';

// Components
import Button from '../../../../../../../components/common/Button/Button';
import MultiToggle from '../../../../../../../components/common/MultiToggle/MultiToggle';
import MultiToggleOption from '../../../../../../../components/common/MultiToggle/MultiToggleOption';
import ImageUpload from '../../../../../../../components/common/ImageUpload/ImageUpload';

// Types 
import { TCharacter } from '@shared/types/Character';
import ServerRequest from '../../../../../../../ServerRequest';

// Contexts 
import { DynamicDataContext } from '../../../../../../../api/DynamicData';
import { UIContext } from '../../../../../../../api/UI';

// Images
import resetIcon from './../../../../../../../assets/icons/reset-small-white.png';

interface IForm {
    selectedCharacter: TCharacter | null;
    characters: TCharacter[] | null;
}

type TFieldValue = string | boolean | number;
type TFieldToUpdate = [
    string, TFieldValue
]

const genders = [
    ['male', 'He/Him'],
    ['female', 'She/Her'],
];

export default function Form(props: IForm) {
    const { selectedCharacter } = props;
    const { characters, user } = useContext(DynamicDataContext);
    const { notifications } = useContext(UIContext);

    const {
        id,
        name,
        gender,
        bio,
        image_url
    } = selectedCharacter ?? {};


    const [ nameBuffer, setNameBuffer ] = useState(name ?? '');
    const [ genderBuffer, setGenderBuffer ] = useState(genders.find(g => g[0] === gender) ?? null);
    const [ bioBuffer, setBioBuffer ] = useState(bio ?? '');
    const [ imageBuffer, setImageBuffer ] = useState<File | string | null>(image_url ?? '');

    const [ imagePreviewUrl, setImagePreviewUrl ] = useState<string | null >(null);
    const [ imageUploadKey, setImageUploadKey ] = useState(Date.now());

    useEffect(() => {
        setNameBuffer(name ?? '');
        setGenderBuffer(genders.find(g => g[0] === gender) ?? null);
        setBioBuffer(bio ?? '');
        setImageBuffer(image_url ?? '');
    }, [ selectedCharacter, id, name, gender, bio, image_url ]);

    function handleChangeNameBuffer(event: React.ChangeEvent<HTMLInputElement>) {
        setNameBuffer(event.target.value);
    }

    function handleChangeBioBuffer(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setBioBuffer(event.target.value);
    }

    function getFieldsToUpdate(): TFieldToUpdate[] {
        const fieldsToUpdate: TFieldToUpdate[] = [];

        if (nameBuffer && nameBuffer !== name) {
            fieldsToUpdate.push(['name', nameBuffer]);
        }

        if (genderBuffer && genderBuffer[0] !== gender) {
            fieldsToUpdate.push(['gender', genderBuffer[0]]);
        }

        if (bioBuffer && bioBuffer !== bio) {
            fieldsToUpdate.push(['bio', bioBuffer]);
        }

        return fieldsToUpdate;
    }

    function handleResetFields() {
        const oldGender = genders.find(g => g[0] === gender);

        name && setNameBuffer(name);
        oldGender && setGenderBuffer(oldGender);
        bio && setBioBuffer(bio);

        if (image_url) {
            setImageBuffer(image_url);
        } else {
            setImageBuffer('');
        }

        setImagePreviewUrl(null);
        setImageUploadKey(Date.now());
    }

    function getCanSubmit(): boolean {
        if (nameBuffer && nameBuffer !== name) {
            return true;
        }

        if (genderBuffer && genderBuffer[0] !== gender) {
            return true;
        }

        if (bioBuffer && bioBuffer !== bio) {
            return true;
        }

        if (imageBuffer && imageBuffer !== image_url) {
            return true;
        }

        return false;
    }

    function getCanEdit() {
        return user.get?.access_level !== 1 ?? false;
    }

    async function handleSubmit() {
        let isSuccessful = false;

        // Handle image
        if (imageBuffer !== image_url && imageBuffer instanceof File && id) {
            const result = await ServerRequest.uploadImage(
                imageBuffer,
                'characters',
                id.toString(),
                notifications
            );

            characters.set((prev: TCharacter[]) => {
                const characterIndex = prev.findIndex(c => c.id === id);
                prev[characterIndex].image_url = result.data.image_url;

                return [...prev];
            })

            isSuccessful = true;
            setImageBuffer(result.data.image_url);
            setImageUploadKey(Date.now());
        }

        // Handle data
        const fieldsToUpdate = getFieldsToUpdate();
        if (fieldsToUpdate.length > 0) {
            const result = await ServerRequest.post('update_character', { data:
                {
                    id,
                    fieldsToUpdate
                }
            });

            if (result.status === 200) {
                isSuccessful = true;
    
                characters.set((prev: any) => {
                    const characterIndex = prev.findIndex((c: TCharacter) => c.id === id);
    
                    fieldsToUpdate.forEach(field => {
                        if (characterIndex > -1) {
                            prev[characterIndex][field[0]] = field[1];   
                        }
                    })
    
                    return [...prev]
                })
            }
        }

        if (!isSuccessful) {
            notifications.add({
                type: 'error',
                title: 'Error',
                content: 'Failed to update character information.'
            });
        }
    }

    return (
        <div className={`Form ${!getCanEdit() ? 'inactive' : ''}`}>
            <section className='top-nav'>
                <span className='col'>
                    <div className='title'>
                        Edit Information
                    </div>
                </span>

                <span className='col'>
                    <Button
                        clickEvent={handleResetFields}
                        type={getCanSubmit() ? 'neutral' : 'inactive'}
                    >
                    <img src={resetIcon} alt='' />
                    </Button>
                    <Button type={getCanSubmit() ? 'constructive' : 'inactive'} clickEvent={handleSubmit}>Save</Button>
                </span>
            </section>

            <section className='form'>
                <section className='media'>
                    <div className='profile-picture'>
                        Profile Picture:
                        <div className='image-placeholder'>
                            {
                                imagePreviewUrl 
                                    ? <img className='image' src={imagePreviewUrl} alt='' /> 
                                    : image_url 
                                    && <img className='image' src={image_url} alt='' />
                            }
                        </div>
                    </div>

                    {
                        <>
                            <ImageUpload 
                                key={imageUploadKey}
                                setImage={setImageBuffer} 
                                setImagePreview={setImagePreviewUrl} 
                            />
                            
                            <div className='info'>
                                <li>Picture should be in the ratio 2:3.</li>
                                <li>Supported extensions: png, jpg, webp.</li>
                                <li>Max file size is 2MB.</li>
                            </div>
                        </>
                    }
                </section>

                <section className='divider' />

                <section className='text'>
                    <section className='two-columns'>
                        <section className='name'>
                            <div className='label'>
                                Full Name:
                            </div>
                            <input type='text' value={nameBuffer} onChange={handleChangeNameBuffer}/>
                        </section>

                        <section className='gender'>
                            <div className='label'>
                                Pronouns:
                            </div>
                            <MultiToggle>
                                {
                                    genderBuffer && genders.map(gender => (
                                        <MultiToggleOption 
                                            key={`GenderOption${gender[0]}`}
                                            clickEvent={() => {setGenderBuffer(gender)}} 
                                            isActive={gender[0] === genderBuffer[0]}>
                                            {gender[1]}
                                        </MultiToggleOption>
                                    ))
                                }
                            </MultiToggle>
                        </section>
                    </section>

                    <section className='bio'>
                        <div className='label'>
                            Biography:
                        </div>
                        <textarea placeholder='Write something about your character...' value={bioBuffer} onChange={handleChangeBioBuffer} />
                    </section>
                </section>
            </section>
        </div>
    )
}