interface IMultiToggle {
    children: any;
}

export default function MultiToggle(props: IMultiToggle) {

    const { children } = props;

    return (
        <div className='MultiToggle'>
            {children}
        </div>
    )
}