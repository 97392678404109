import { useState, useCallback } from 'react';
import IconButton from './../../common/IconButton/IconButton';

// Icons
import copyIcon from './../../../assets/icons/copy-black.png';
import successIcon from './../../../assets/icons/check-mark-black.png';

interface ICopyFieldProps {
    content: string;
}

export default function CopyField({ content }: ICopyFieldProps) {

    const [ textCopied, setTextCopied ] = useState(false);

    const copyToClipBoard = useCallback(async () => {

        try {
            await navigator.clipboard.writeText(content)
        } catch (err) {
            console.error('Failed to copy content: ', err);
            return;
        }

        setTextCopied(true);

        const timeout = setTimeout(() => {
            setTextCopied(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className='CopyField'>
            <span className='content'>{content}</span>
            <span className='copy'>
                {!textCopied 
                    ? <IconButton type='none' clickEvent={copyToClipBoard}>
                        <img src={copyIcon} alt='' />
                    </IconButton>
                    : <img src={successIcon} alt='' />
                }
                </span>
        </div>
    )
}