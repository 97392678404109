import { MouseEventHandler } from "react";

interface IMultiToggleOption {
    clickEvent: MouseEventHandler<HTMLButtonElement>;
    isActive: boolean;
    children?: any;
    type?: string;
    disabled?: boolean;
}


export default function MultiToggleOption(props: IMultiToggleOption) {

    const {
        clickEvent, 
        isActive, 
        children, 
        type,
        disabled
    } = props;

    return (
        <button 
            className={`MultiToggleOption ${isActive && 'active'} ${type} ${disabled ? 'inactive' : ''}`}
            onClick={clickEvent}>
            {children}
        </button>
    )

}