import SimpleForm, { type ISimpleForm } from "../../../../../../components/common/SimpleForm/SimpleForm";

import { useContext } from "react";
import { DynamicDataContext } from "../../../../../../api/DynamicData";

export default function Venue() {
    const {
        realm
    } = useContext(DynamicDataContext);

    let formData: ISimpleForm | undefined;

    if (
        realm.get?.name &&
        realm.get?.address &&
        realm.get?.address &&
        realm.get?.founding_date &&
        realm.get?.link
    ) {
        formData = {
            styleOptions: {
                width: 'center'
            },
            data: [
                {
                    title: 'Name',
                    key: 'name',
                    value: realm.get?.name,
                    inputType: typeof realm.get?.name
                },
                {
                    title: 'Address',
                    key: 'address',
                    value: realm.get?.address,
                    inputType: 'string',
                    textarea: true
                },
                {
                    title: 'Founding Date',
                    key: 'founding_date',
                    value: realm.get?.founding_date,
                    inputType: 'date'
                },
                {
                    title: 'Website / Carrd',
                    key: 'link',
                    value: realm.get?.link,
                    inputType: typeof realm.get?.link
                },
                {
                    title: 'Discord Invite Link',
                    key: 'discord_invite_link',
                    value: realm.get?.discord_invite_link,
                    inputType: typeof realm.get?.discord_invite_link
                }
            ],
            requestType: 'update',
            requestHandler: {
                target: 'update_realm'
            },
            responseHandler: realm.set,
            responseType: 'object'
        }
    }

    return (
        <>
            {formData &&
                <SimpleForm 
                    formData={formData}
                />
            }
            
        </>
    )
}