// Components
import Button from '../../../../../../../components/common/Button/Button';

interface IFirstCharacterPrompt {
    handleCreateCharacter: () => void;
}

export default function FirstCharacterPrompt({
    handleCreateCharacter
}: IFirstCharacterPrompt) {

    return(
        <div className='FirstCharacterPrompt'>
            <div className='container'>
                <div className='row'>
                    <div className='headline'>Uh oh, looks a bit empty here...</div>
                    <div className='subline'>Let's fix that!</div>
                </div>

                <div className='row'>
                    <Button type='constructive' clickEvent={handleCreateCharacter}>
                        Create Character
                    </Button>
                </div>
            </div>
        </div>
    )
}