import { useLayoutEffect, useRef } from 'react';

// Types
import { TCharacter } from '@shared/types/Character';
import { IView } from '../../Dashboard';

// Icons
import CloseIcon from './../../../../assets/icons/close-white.png';

// Components
import Button from './../../../../components/common/Button/Button';
import Navigation from '../Navigation/Navigation';

// Animations
import gsap from 'gsap';
import animations from '../../../../utils/animations';

interface OverlayProps {
    selectedView: number;
    setSelectedView: React.Dispatch<React.SetStateAction<number>>;
    activeCharacter: TCharacter | null;
    views: IView[];
    height: string;
    setDisplayOverlay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Overlay({
    selectedView,
    setSelectedView,
    activeCharacter,
    views,
    height,
    setDisplayOverlay
}: OverlayProps) {

    const ref = useRef(null);
    useLayoutEffect(() => {
        const timeline = gsap.timeline();

        timeline.to(ref.current, animations.fadeIn, 0);

        return () => {
            timeline.kill();
        }
    }, []);

    function handleClose(){
        gsap.to(ref.current, animations.fadeOut);

        setTimeout(()=> {
            setDisplayOverlay(false)
        }, 100)
    }

    return (
        <div className='Overlay' style={{ height: height }} ref={ref}>
            <div className='toggle-overlay'>
                <Button type='neutral' clickEvent={handleClose}>
                    <img src={CloseIcon} alt='Close Menu' />
                </Button>
            </div>

            <div className='content'>
                <Navigation 
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    activeCharacter={activeCharacter}
                    views={views}
                    closeOnNavigate={true}
                    setDisplayOverlay={setDisplayOverlay}
                />
            </div>
        </div>
    )
}