import { useState } from 'react';

// Types
import { TServiceCategory } from '@shared/types/ServiceCategory';

export interface IUseServiceCategories {
    get: TServiceCategory[] | null;
    add: (T: TServiceCategory) => void;
    getItemById: (T: number) => TServiceCategory | undefined;
    set: React.Dispatch<React.SetStateAction<TServiceCategory[]>>
    remove: (T: number) => void;
    fill: (T: TServiceCategory[]) => void;
}

export default function useServiceCategories(): IUseServiceCategories {
    const [ serviceCategories, setServiceCategories ] = useState<TServiceCategory[]>([]);

    function fill(data: TServiceCategory[]) {
        setServiceCategories(data);
    }

    function add(item: TServiceCategory) {
        setServiceCategories(prev => ([...prev, item]))
    }

    function remove(itemId: number) {
        setServiceCategories(prev => prev.filter(i => i.id !== itemId));
    }

    function getItemById(id: number) {
        return serviceCategories.find(i => i.id === id);
    }

    return {
        get: serviceCategories,
        add,
        getItemById,
        set: setServiceCategories,
        remove,
        fill,
    }
}