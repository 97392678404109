import { useContext, useState, useMemo } from 'react';

// Contexts
import { DynamicDataContext } from '../../../../../../api/DynamicData';
import { UIContext } from '../../../../../../api/UI';

// Components
import Button from '../../../../../../components/common/Button/Button';
import Toggle from '../../../../../../components/common/Toggle/Toggle';
import Characters from './Characters/Characters';
import Form from './Form/Form';
import SafetyConfirmModal from '../../../../../../components/common/Modal/SafetyConfirmModal';

// Utils
import Time from '../../../../../../utils/time';

// Types
import { TCharacter } from '@shared/types/Character';
import ServerRequest from '../../../../../../ServerRequest';
import FirstCharacterPrompt from './FirstCharacterPrompt/FirstCharacterPrompt';

export default function Character() {
    const { user, characters } = useContext(DynamicDataContext);
    const { modals, notifications } = useContext(UIContext);

    const [ selectedCharacter, setSelectedCharacter ] = useState<TCharacter | null>(characters.getCharactersByUserId(user.get?.id)?.[0] ?? null);
    const {
        id,
        name,
        hired_date,
        positions,
        is_enabled,
        is_public,
    } = selectedCharacter ?? {};

    const userCharacters = useMemo(() => (
        characters.getCharactersByUserId(user.get?.id)
    ), [ characters, user.get?.id ]);

    const activeCharacter = useMemo(() => (
        userCharacters.find(c => c.id === user.get?.active_character_id)
    ), [ userCharacters, user.get?.active_character_id ]);

    const isActive = activeCharacter?.id === id || false;

    function capitalizeWords(full: string): string {
        return full
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
    }
    
    function parsePositions(positions: string) {
        const capitilizedFirstLetters = capitalizeWords(positions.split(',').join(' ')).split(' ');

        if (capitilizedFirstLetters.length <= 2) return capitilizedFirstLetters.join(' & ');

        const firstPositions = [...capitilizedFirstLetters].slice(0, -1);
        const allPositions = `${firstPositions.join(', ')} & ${capitilizedFirstLetters[capitilizedFirstLetters.length -1]}`;

        return allPositions;
    }

    positions && parsePositions(positions);

    async function handleCreateCharacter() {
        const result = await ServerRequest.post('create_character', {});
        const character = result.data.character;

        if (result.status === 200) {
            characters.add(character);
            setSelectedCharacter(character);
            notifications.add({
                type: 'confirmation',
                title: 'Success',
                content: 'Character created!'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    async function handleDelete() {
        if (!selectedCharacter) return;

        modals.add(
            <SafetyConfirmModal 
                headline={'Are you sure?'}
                message={
                    'This will delete your character along with all related data and media.'
                }
                safetyMessage={'You must type out the name of the character to proceed.'}
                safetyPrompt={name!}
                callback={proceed}
            />
        )

        async function proceed() {
            const result = await ServerRequest.post('delete_character', {character_id: selectedCharacter!.id});
            const characterIdToRemove = result.data.character_id;
    
            const characterToSwitchTo = userCharacters.find(c => c.id !== characterIdToRemove) || null;
    
            if (result.status === 200) {
                setSelectedCharacter(characterToSwitchTo);
                characters.remove(characterIdToRemove);
    
                notifications.add({
                    type: 'confirmation',
                    title: 'Success',
                    content: 'Character deleted.'
                });
            } else {
                notifications.add({
                    type: 'error',
                    content: result.data,
                    title: 'Error'
                });
            }
        }
    }

    async function handleToggleEnabled() {
        const result = await ServerRequest.post('update_character', {
            data: {
                id: id,
                fieldsToUpdate: [['is_enabled', !is_enabled]]
            }
        });

        if (result.status === 200) {
            characters.setEnabled(id!, result.data.updatedFields.is_enabled);
        } else {
            notifications.add({
                type: 'error',
                title: 'Error',
                content: 'Failed to toggle enabled.'
            });
        }
    }
    
    async function handleSetActive() {
        const result = await ServerRequest.post('update_user', {
            data: {
                user_id: user.get?.id,
                fieldsToUpdate: [['active_character_id', id]]
            }
        });

        if (result.status === 200) {
            user.setActive(result.data.updatedFields.active_character_id)
        }
    }

    async function handleSetPublic(value: boolean) {
        if (!selectedCharacter) return;

        const result = await ServerRequest.post('update_character', {
            data: {
                id: selectedCharacter.id,
                fieldsToUpdate: [['is_public', value]]
            }
        });

        if (result.status === 200) {
            characters.setField(selectedCharacter.id, 'is_public', result.data.updatedFields.is_public as boolean)
        }
    }

    function getCanEdit() {
        return user.get?.access_level !== 1 ?? false;
    }

    return (
        <div className='Character'>
            {selectedCharacter
                ? <>
                    <div className='top-bar'>
                        <span className='name'>{name}</span>
                        <span>
                            <Characters 
                                selectedCharacter={selectedCharacter} 
                                setSelectedCharacter={setSelectedCharacter} 
                                handleCreateCharacter={handleCreateCharacter}
                                userCharacters={userCharacters}
                                user={user.get}
                                canEdit={getCanEdit}
                            />
                        </span>
                    </div>

                    <div className='addendum-bar'>
                        <span>
                            {positions && parsePositions(positions)}
                        </span>
                        <span>
                            <div>Member since {hired_date && Time.epochToDate(hired_date)}</div>
                            <div className='under-title'>{hired_date && Time.getTimeSinceDate(hired_date)}</div>
                        </span>
                    </div>

                    <div className='addendum-bar danger-zone'>
                        <span className='col'>
                            <span className='col'>
                                <Button 
                                    type='destructive'
                                    inactive={!getCanEdit()}
                                    clickEvent={handleDelete}
                                >
                                    Delete Character
                                </Button>
                            </span>

                            <span className='col'>
                                <div  className={`switch ${!getCanEdit() ? 'inactive' : ''}`}>
                                    <span className='label'>Enabled:</span>
                                    <Toggle value={is_enabled} clickEvent={handleToggleEnabled}></Toggle>
                                </div>
                            </span>

                            <span className='col'>
                                <div  className={`switch ${!getCanEdit() ? 'inactive' : ''}`}>
                                    <span className='label'>Public:</span>
                                    <Toggle value={is_public} clickEvent={() => {handleSetPublic(!is_public)}}></Toggle>
                                </div>
                            </span>
                        </span>

                        <span className='col'>
                            <div className='switch'>
                                <span className='label'>Active Character:</span>
                                <Toggle value={isActive} clickEvent={handleSetActive}></Toggle>
                            </div>
                        </span>
                    </div>
                    
                    <Form characters={characters.get} selectedCharacter={selectedCharacter} />
                </>
                : <>
                    <FirstCharacterPrompt handleCreateCharacter={handleCreateCharacter}/>
                </>
            }
        </div>
    )
}