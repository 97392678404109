import { useState, useContext } from 'react';

// Contexts
import { UIContext } from '../../../api/UI';

// Components
import Modal from "./Modal";
import Button from '../Button/Button';

export type TConfirmModal = {
    id?: number;
    headline: string;
    message?: string;
    callback: Function;
    confirmMessage?: string;
    cancelMessage?: string;
}

export default function ConfirmModal({
    id,
    headline,
    message,
    callback,
    confirmMessage = 'Confirm',
    cancelMessage = 'Cancel',
}: TConfirmModal) {
    const { modals } = useContext(UIContext);

    function handleConfirm() {
        callback();
        modals.closeCurrent();
    }

    function handleCancel() {
        modals.closeCurrent();
    }

    return(
        <Modal
            id={id}
            headline={headline}
            message={message}
        >

            <nav className='options'>
                <Button 
                    type='constructive'
                    clickEvent={handleConfirm}
                >
                    {confirmMessage}
                </Button>

                <Button 
                    type='destructive'
                    clickEvent={handleCancel}
                >
                    {cancelMessage}
                </Button>
            </nav>
        </Modal>
    )
}