import { useState, useRef, useEffect, useContext, useMemo } from 'react';

// Components
import VenueStats from './components/VenueStats/VenueStats';
import ModalManager from './components/ModalManager/ModalManager';
import EnvironmentNotice from '../../components/EnvironmentNotice/EnvironmentNotice';
import NotificationManager from './components/NotificationManager/NotificationManager';
import Profile from './components/Profile/Profile';
import Management from './components/Management/Management';
import Sidebar from './components/Sidebar/Sidebar';
import Overlay from './components/Overlay/Overlay';
import CookieBanner from './../../components/common/CookieBanner/CookieBanner';

// Contexts
import { DynamicDataContext } from '../../api/DynamicData';

// Images
import profileImage from '../../assets/icons/user.png';
import homeImage from '../../assets/icons/home.png';
import settingsImage from '../../assets/icons/settings-white.png';

// Types
import { TCharacter } from '@shared/types/Character';

export interface IView { 
    image: JSX.Element | null; 
    title: string;
    component: React.ReactNode;
} 

export default function Dashboard() {
    const {
        user,
        characters,
    } = useContext(DynamicDataContext);

    const views: IView[] = [
        { image: <img src={homeImage} alt='' />, title: 'Home', component: <VenueStats /> },
        { image: <img src={profileImage} alt='' />, title: 'Profile', component: <Profile /> },
    ]; 
    
    if (user.get?.access_level && user.get?.access_level >= 4) {
        views.push(
            { image: <img src={settingsImage} alt='' />, title: 'Management', component: <Management /> }
        )
    } 

    const activeCharacter: TCharacter | null = useMemo(() => (
        characters.get.find(c => c.id === user.get?.active_character_id) || null
    ), [characters.get, user.get]) || null

    const [ selectedView, setSelectedView ] = useState(0);
    const [ wrapperHeight, setWrapperHeight ] = useState('100vh');
    const [ envNoticeHeight, setEnvNoticeHeight ] = useState(0);
    const [ displayOverlay, setDisplayOverlay ] = useState(false);
    
    const EnvironmentNoticeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const envNoticeElement = EnvironmentNoticeRef.current;
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              const target = entry.target as HTMLElement;
              setEnvNoticeHeight(target.offsetHeight);
            }
          });

        if (envNoticeElement) {
            resizeObserver.observe(envNoticeElement);
        }

        return () => {
            if (envNoticeElement) {
                resizeObserver.unobserve(envNoticeElement);
            }
        };
    }, []);

    useEffect(() => {
        setWrapperHeight(`calc(100vh - ${envNoticeHeight}px)`);
    }, [envNoticeHeight]);

    return (
        <>
            <ModalManager />
            <NotificationManager />
            <div className='Dashboard'>

                <EnvironmentNotice ref={EnvironmentNoticeRef} />

                <div className='wrapper' style={{ height: wrapperHeight }}>

                    {
                        displayOverlay &&   
                            <Overlay 
                                setDisplayOverlay={setDisplayOverlay}
                                selectedView={selectedView}
                                setSelectedView={setSelectedView}
                                activeCharacter={activeCharacter}
                                views={views}
                                height={wrapperHeight}
                            />
                    }

                    <Sidebar 
                        setDisplayOverlay={setDisplayOverlay}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        activeCharacter={activeCharacter}
                        views={views}
                        height={wrapperHeight}
                    />

                    <main>
                        {views[selectedView].component}
                    </main>
                </div>
            </div>
        </>
    );
}