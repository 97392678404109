import { useContext } from "react";

// Contexts
import { UIContext } from "../../../../../../../../../api/UI";
import { DynamicDataContext } from "../../../../../../../../../api/DynamicData";

// Components
import Button from "../../../../../../../../../components/common/Button/Button";
import DeleteButton from "../../../../../../../../../components/common/Button/_DeleteButton";
import Toggle from "../../../../../../../../../components/common/Toggle/Toggle";
import EditCharacter from "./EditCharacter/EditCharacter";

// Modals
import ConfirmModal from "../../../../../../../../../components/common/Modal/ConfirmModal";

// Types
import { TCharacter } from "@shared/types/Character";
import { TUser } from "@shared/types/User";

// Utils
import ServerRequest from "../../../../../../../../../ServerRequest";

interface IEditCharactersByUserProps {
    user: TUser;
}

export default function EditCharactersByUser({
    user
}: IEditCharactersByUserProps) {

    const { modals, notifications } = useContext(UIContext);
    const { characters, users } = useContext(DynamicDataContext);
    const charactersByUser = characters.getCharactersByUserId(user.id); 

    function handleEditModal(character: TCharacter) {
        modals.add(<EditCharacter character={character} />)
    }

    async function deleteCharacter(characterId: number) {
        const result = await ServerRequest.post('delete_character', { character_id: characterId });

        const characterIdToRemove = result.data.character_id;

        if (result.status === 200) {
            characters.remove(characterIdToRemove);

            notifications.add({
                type: 'confirmation',
                title: 'Success',
                content: 'Character deleted.'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    function handleDeleteModal(character: TCharacter) {
        modals.add(
            <ConfirmModal 
                headline='Delete Character'
                callback={() => {deleteCharacter(character.id)}}
                message={`Are you sure you want to delete ${character.name}?`}
            />
        )
    }

    async function createCharacter(userId: number) {
        const result = await ServerRequest.post('create_character', {target_user_id: userId});
        const character = result.data.character;

        if (result.status === 200) {
            characters.add(character);

            notifications.add({
                type: 'confirmation',
                title: 'Success',
                content: 'Character created!'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    function handleCreateModal() {
        modals.add(
            <ConfirmModal 
                headline='Create New Character'
                callback={() => createCharacter(user.id)}
                message={`Are you sure you want to create a new character for ${user.username}?`}
            />
        )
    }

    function getIsActiveCharacter(character: TCharacter) {
        return users.getUserById(character.user_id)?.active_character_id === character.id
    }

    async function handleSetActive(characterId: number) {
        const result = await ServerRequest.post('update_user', {
            data: {
                user_id: user.id,
                fieldsToUpdate: [['active_character_id', characterId]]
            }
        });

        if (result.status === 200) {
            users.setActiveCharacter(user.id, result.data.updatedFields.active_character_id)
        }
    }

    return (
        <div className='EditCharactersByUser'>
            {
                charactersByUser.map((character, index) => (
                    <span className={`character`} key={`Character${index}`}>
                        {character.name} 

                        <nav>
                            <Button 
                                type='dark' 
                                clickEvent={() => handleEditModal(character)}
                            >Edit</Button>

                            <nav>
                                <span>Active:</span>
                                <Toggle 
                                    value={getIsActiveCharacter(character)}
                                    isDisabled={getIsActiveCharacter(character)}
                                    clickEvent={() => {handleSetActive(character.id)}} 
                                />
                            </nav>

                            <DeleteButton 
                                clickEvent={() => {handleDeleteModal(character)}} 
                            />
                                
                        </nav>
                    </span>
                ))
            }

            <Button type='constructive' clickEvent={(handleCreateModal)}>New Character</Button>
        </div>
    )
}