import { useState } from 'react';

// Types
import { TUser } from '@shared/types/User';

export interface IUseUser {
    get: TUser | null;
    fill: (T: TUser) => void;
    setActive: (T: number) => void;
}

export default function useUser(): IUseUser {
    const [ user, setUser ] = useState<TUser | null>(null);

    function fill(data: TUser) {
        setUser(data);
    }

    function setActive(characterId: number) {

        setUser(prev => {
            if (prev === null) {
                return null;
            }
    
            const updatedUser: TUser = {
                ...prev,
                active_character_id: characterId,
            };
    
            return updatedUser;
        });
    }

    return {
        get: user,
        fill,
        setActive,
    }
}