import { useContext, useMemo, useState } from "react";

// Contexts
import { DynamicDataContext } from "../../../../../../../api/DynamicData";
import { UIContext } from "../../../../../../../api/UI";

// Components
import DiningItem from "./DiningItem";
import { TDiningItem } from "@shared/types/DiningItem";
import SearchFilter from '../../../../../../../components/common/SearchFilter/SearchFilter';
import Button from '../../../../../../../components/common/Button/Button';
import DeleteButton from '../../../../../../../components/common/Button/_DeleteButton';

// Types
import { TDiningCategory } from "@shared/types/DiningCategory";

// Utils
import ServerRequest from "../../../../../../../ServerRequest";

// Modals
import CreateDiningItem from "./CreateDiningItem";
import EditDiningCategory from "./EditDiningCategory";
import CreateDiningCategory from "./CreateDiningCategory";
import ConfirmModal from "../../../../../../../components/common/Modal/ConfirmModal";

export default function DiningMenu({ reset }: any) {

    const { diningCategories, diningMenu } = useContext(DynamicDataContext);
    const { modals, notifications } = useContext(UIContext);

    const [ filteredItems, setFilteredItems ] = useState(diningMenu.get ?? []);

    const categories = useMemo(() => {
        let categories: any = {};

        diningCategories.get?.forEach(category => {
            categories[category.id] = {
                name: category.name,
                id: category.id,
                items: []
            };
        });

        filteredItems?.forEach(item => {
            categories[item.category_id]?.items.push(item)
        });

        return categories
    }, [ diningCategories.get, filteredItems ]);

    function handleFilter(output: any) {
        const [ itemResult ] = output;
        setFilteredItems(itemResult);
    }

    function handleCreateItem(categoryId: number, categoryName: string) {
        modals.add(<CreateDiningItem 
            reset={reset}
            categoryId={categoryId}
            categoryName={categoryName}
        />)
    }

    function handleCreateCategory() {
        modals.add(<CreateDiningCategory 
            reset={reset}
        />)
    }

    async function removeCategory(item: TDiningCategory) {
        const result = await ServerRequest.post('delete_menu_category', {item_id: item.id, menu_type: 'dining'});

        if (result.status === 200) {
            diningCategories.remove(item.id);
            reset && reset();

            notifications.add({
                type: 'confirmation',
                content: `${item.name} has been deleted.`,
                title: 'Category Deleted'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    function handleRemoveCategory(category?: TDiningCategory) {
        if (!category) return;

        modals.add(
            <ConfirmModal 
                headline='Delete category'
                message={`Are you sure you want to delete ${category.name}?`}
                callback={() => {removeCategory(category)}}
            />
        )
    }

    function handleEditCategory(category?: TDiningCategory) {
        if (!category) return;

        modals.add(<EditDiningCategory
            reset={reset}
            item={category}
        />)
    }

    const itemsToFilter = [
        {
            name: 'Items',
            items: diningMenu.get,
            filterBy: 'name'
        }
    ];

    return (
        <div className='Menu'>
            <div className='top-nav'>
                <SearchFilter inputs={itemsToFilter} handleOutput={handleFilter} searchLabel={'nomnoms'} />
                <Button type='constructive' clickEvent={handleCreateCategory}>Add Category</Button>
            </div>

            <div className='categories'>
                {
                    Object.keys(categories)
                    .filter(categoryId => {
                        if (filteredItems.length !== diningMenu.get?.length) {
                            return categories[categoryId].items.some((item: any) => filteredItems.includes(item));
                        }

                        return true;
                    })
                    .map(categoryId => (
                        <div className='category' key={categoryId}>
                            <div className='title'> 
                                <span className='name'>{categories[categoryId].name} </span>
                                <nav>
                                    <Button 
                                        type='dark' 
                                        clickEvent={() => {handleEditCategory(diningCategories.getItemById(parseInt(categoryId)))}}>
                                        Edit
                                    </Button>
                                    <Button 
                                        type='constructive' 
                                        clickEvent={() => {handleCreateItem(parseInt(categoryId), categories[categoryId].name)}}>
                                        Add Item
                                    </Button>
                                    <DeleteButton clickEvent={() => {handleRemoveCategory(diningCategories.getItemById(parseInt(categoryId)))}} />
                                </nav>
                            </div>
                            <div className='items'>
                            {
                                categories[categoryId].items
                                    .filter((item: any) => filteredItems.includes(item))
                                    .map((item: TDiningItem, index: number) => (
                                        <div className='item' key={`key${index}`}>
                                            <DiningItem item={item} reset={reset} />
                                        </div>
                                    ))
                            }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}