import { useState, useEffect, forwardRef } from "react";
import ServerRequest from "../../ServerRequest";

export default forwardRef(function EnvironmentNotice(_?, ref?: any) {

    const [ environment, setEnvironment ] = useState('live');

    useEffect(() => {
        ServerRequest.getEnvironment()
            .then(res => setEnvironment(res.data));
    }, []);

    return (
        <div className='EnvironmentNotice' ref={ref && ref}>
            {
                environment !== 'live' &&
                <div className='text'>
                    You are currently running on {environment} environment. 
                    Please visit <a href='https://www.littlekiwi.app'>www.littlekiwi.app</a> for the live version.
                </div>
            }
        </div>
    )
});