import { useContext } from 'react';

// Components
import Button from '../../../../../../../components/common/Button/Button';
import Dropdown from '../../../../../../../components/common/Dropdown/Dropdown';
import DropdownItem from '../../../../../../../components/common/Dropdown/DropdownItem';

// Contexts
import { UIContext } from '../../../../../../../api/UI';

// Modals 
import ConfirmModal from '../../../../../../../components/common/Modal/ConfirmModal';

// Types 
import { TCharacter } from '@shared/types/Character';
import { TUser } from '@shared/types/User';

interface ICharacters {
    selectedCharacter: TCharacter | null;
    setSelectedCharacter: (T: any) => void;
    handleCreateCharacter: () => void;
    userCharacters: TCharacter[];
    user: TUser | null;
    canEdit: () => boolean;
}

export default function Characters(props: ICharacters) {
    const {
        userCharacters,
        selectedCharacter,
        setSelectedCharacter,
        handleCreateCharacter,
        user,
        canEdit
    } = props;

    const { modals } = useContext(UIContext);

    function handleCreateCharacterModal() {
        modals.add(<ConfirmModal
            headline='New Character'
            message='Create a new character?'
            callback={handleCreateCharacter}
        />)
    }

    return (
        <div className='Characters'>
            <Dropdown 
                value={selectedCharacter?.name || ''}
                onChangeEvent={({ target }) => setSelectedCharacter(userCharacters[target.selectedIndex])}
            >
                {
                    userCharacters && userCharacters.map((character: TCharacter, index: number) => (
                        <DropdownItem 
                            key={`Character${index}`}
                            value={character.name}
                        >
                            {character.name}&nbsp;
                            {character.id === user?.active_character_id && <>* &nbsp;</>}
                        </DropdownItem>
                    ))
                }

            </Dropdown>
            {
                <span className={!canEdit() ? 'inactive' : ''}>
                    <Button type='constructive' clickEvent={handleCreateCharacterModal}>New Character</Button>
                </span>
            }
        </div>
    )
}