import { useState } from 'react';

// Types
import { TUser } from '@shared/types/User';

export interface IUseUsers {
    get: TUser[] | null;
    set: React.Dispatch<any>;
    remove: (T: TUser) => void;
    add: (T: TUser) => void;
    getUserById: (T: number | undefined) => TUser | undefined;
    setActiveCharacter: (userId: number, characterId: number) => void;
    fill: (T: TUser[]) => void;
}

export default function useUsers(): IUseUsers {
    const [ users, setUsers ] = useState<TUser[] | null>(null);

    function fill(data: TUser[]) {
        setUsers(data);
    }

    function remove(user: TUser) {
        if (!users) return;

        setUsers(prev => prev!.filter(u => u !== user));
    }

    function add(user: TUser) {
        if (!users) return;

        setUsers(prev => [...prev!, user])
    }

    function getUserById(userId?: number) {
        return users?.find(u => u.id === userId);
    }

    function setActiveCharacter(userId: number, characterId: number) {
        setUsers(prev => {
            if (!prev) return prev;

            const index = users?.findIndex(u => u.id === userId);
            if (index === -1 || index === undefined) return prev;

            prev[index].active_character_id = characterId
    
            return [...prev];
        });
    }

    return {
        get: users,
        set: setUsers,
        add,
        getUserById,
        setActiveCharacter,
        remove,
        fill,
    }
}