import CheckmarkIcon from './../../../../../../../../components/common/Icon/CheckmarkIcon';
import CrossmarkIcon from './../../../../../../../../components/common/Icon/CrossmarkIcon';

export default function PermissionsTable() {
    return (
        <div className='PermissionsTable'>
            <table>
                <tr>
                    <th className='permission'></th>
                    <th className='permission'>Guest</th>
                    <th className='permission'>User</th>
                    <th className='permission'>Moderator</th>
                    <th className='permission'>Admin</th>
                    <th className='permission'>Owner</th>
                </tr>
                <tr>
                    <td className='action'>Coconut: Basic Usage</td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Coconut: Dangerous Settings</td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Edit name, bio & pronouns of <u>own</u> characters</td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Create/delete <u>own</u> characters</td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Change <u>own</u> password/username</td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Set <u>own</u> characters to public</td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Full management of <u>other</u> users/characters with <i>lower</i> permission</td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                    <td><CheckmarkIcon /></td>
                </tr>
                <tr>
                    <td className='action'>Full management of <u>other</u> users/characters with <i>same</i> permission</td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                    <td><CrossmarkIcon /></td>
                </tr>
            </table>
        
        </div>
    )
}