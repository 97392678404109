import { useState } from 'react';

// Types
import { TServiceItem } from '@shared/types/ServiceItem';

export interface IUseServiceMenu {
    get: TServiceItem[] | null;
    add: (T: TServiceItem) => void;
    set: React.Dispatch<React.SetStateAction<TServiceItem[]>>
    remove: (T: number) => void;
    fill: (T: TServiceItem[]) => void;
}

export default function useServiceMenu(): IUseServiceMenu {
    const [ serviceItems, setServiceItems ] = useState<TServiceItem[]>([]);

    function fill(data: TServiceItem[]) {
        setServiceItems(data);
    }

    function add(item: TServiceItem) {
        setServiceItems(prev => ([...prev, item]))
    }

    function remove(itemId: number) {
        setServiceItems(prev => prev.filter(i => i.id !== itemId));
    }

    return {
        get: serviceItems,
        set: setServiceItems,
        add,
        remove,
        fill,
    }
}