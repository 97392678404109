import { useContext } from "react";

import { type TCharacter } from "@shared/types/Character";
import SimpleForm, { type ISimpleForm } from "../../../../../../../../../../components/common/SimpleForm/SimpleForm";

// Contexts
import { DynamicDataContext } from "../../../../../../../../../../api/DynamicData";

// Components
import ContentModal from "../../../../../../../../../../components/common/Modal/ContentModal";

interface EditCharacterProps {
    id?: number;
    character: TCharacter;
}

export default function EditCharacter({ id, character }: EditCharacterProps) {
    const { characters } = useContext(DynamicDataContext);

    const formData: ISimpleForm = {
        id: character.id,
        data: [
            {
                title: 'Name',
                key: 'name',
                value: character.name,
                inputType: typeof character.name
            },
            {
                title: 'Enabled',
                key: 'is_enabled',
                value: character.is_enabled,
                inputType: typeof character.is_enabled
            },
            {
                title: 'Public',
                key: 'is_public',
                value: character.is_public,
                inputType: typeof character.is_public,
                info: "Decides if character should show up in kiwi's public API."
            },
            {
                title: 'Pronouns',
                key: 'gender',
                value: character.gender,
                inputType: 'string',
                options: [
                    {
                        name: 'He/him',
                        value: 'male'
                    },
                    {
                        name: 'She/her',
                        value: 'female'
                    }
                ],
                info: 'Affects some title adaptions (e.g. waiter/waitress)'
            },
            {
                title: 'Hired Date',
                key: 'hired_date',
                value: character.hired_date,
                inputType: 'date',
            },
            {
                title: 'Positions',
                key: 'positions',
                value: character.positions,
                inputType: typeof character.positions,
                labels: true
            },
            {
                title: 'Biography',
                key: 'bio',
                value: character.bio,
                inputType: 'string',
                textarea: true,
            },
            {
                title: 'Profile Picture',
                key: 'image_url',
                value: character.image_url ?? '',
                inputType: 'image',
                targetFolder: 'characters'
            },
        ],
        requestType: 'update',
        requestHandler: {
            target: 'update_character',
            params: [
                {
                    key: 'id',
                    value: character.id
                }
            ]
        },
        responseHandler: characters.set,
        responseType: 'array'
    }
    
    return (
        <ContentModal headline={character.name} id={id}>
            <SimpleForm formData={formData}/>
        </ContentModal>
    )
}