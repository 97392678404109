import { useContext } from 'react';

// Components
import SimpleForm, { type ISimpleForm } from './../../../../../../../components/common/SimpleForm/SimpleForm';
import ContentModal from './../../../../../../../components/common/Modal/ContentModal';

// Contexts
import { DynamicDataContext } from '../../../../../../../api/DynamicData';

interface ICreateUserModalProps {
    reset?: any;
    id?: number;
}

export default function CreateDiningCategory({ id, reset }: ICreateUserModalProps) {

    const { diningCategories } = useContext(DynamicDataContext);

    let formData: ISimpleForm | undefined;

    if (
        diningCategories.get
    ) {
        formData = {
            styleOptions: {
                submitLabel: 'Create'
            },
            data: [
                {
                    title: 'Name',
                    key: 'name',
                    value: '',
                    inputType: 'string'
                },
            ],
            requestType: 'create',
            requestHandler: {
                target: 'create_menu_category',
                params: [
                    {
                        key: 'menu_type',
                        value: 'dining',
                    },
                ]
            },
            responseHandler: (item: any) => {
                diningCategories.add(item);
                reset();
            },
            responseType: 'object'
        }
    }

    return (
        <>
            {formData &&
                <ContentModal headline={`New Dining Category`} id={id}>
                    <SimpleForm formData={formData}/>
                </ContentModal>
            }
        </>
    )
}