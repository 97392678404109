import { useState, useContext } from 'react';

// Components
import Modal from "./Modal";
import Button from '../Button/Button';

// Contexts
import { UIContext } from '../../../api/UI';

interface ISafetyChangeModal {
    id?: number;
    headline: string;
    message?: string;
    toChange: string;
    callback: Function;
    confirmMessage?: string;
    cancelMessage?: string;
    isEmptyAllowed?: boolean;
    inputType?: 'password' | 'username' | 'text' | 'number';
}

export default function SafetyChangeModal({
    id,
    headline,
    message,
    toChange,
    callback,
    confirmMessage = 'Confirm',
    cancelMessage = 'Cancel',
    isEmptyAllowed = true,
    inputType = 'text',
}: ISafetyChangeModal) {
    const { modals } = useContext(UIContext)

    const [ input, setInput ] = useState('');
    const [ confirmInput, setConfirmInput ] = useState('');

    function handleInput({ target }: any) {
        setInput(target.value);
    }

    function handleConfirmInput({ target }: any) {
        setConfirmInput(target.value);
    }

    function handleConfirm() {
        callback(input);
        modals.closeCurrent();
    }

    function handleCancel() {
        modals.closeCurrent();
    }

    function validate() {
        if (!isEmptyAllowed) {
            if (input === '') return false;
        }

        return input === confirmInput;
    }

    return(
        <Modal
            id={id}
            headline={headline}
            message={message}
        >
            <div className='safety-message'>Enter new {toChange}</div>
            <div className='safety-prompt'>
                <input type={inputType} onChange={handleInput} value={input} />
            </div>

            <div className='safety-message'>Re-enter new {toChange}</div>
            <div className='safety-prompt'>
                <input type={inputType} onChange={handleConfirmInput} value={confirmInput} />
            </div>

            <nav className='options'>
                <Button 
                    type={`constructive`}
                    inactive={!validate()}
                    clickEvent={handleConfirm}
                >
                    {confirmMessage}
                </Button>

                <Button 
                    type='destructive'
                    clickEvent={handleCancel}
                >
                    {cancelMessage}
                </Button>
            </nav>
        </Modal>
    )
}