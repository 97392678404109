import { useContext } from 'react';

// Components
import SimpleForm, { type ISimpleForm } from '../../../../../../../components/common/SimpleForm/SimpleForm';
import ContentModal from '../../../../../../../components/common/Modal/ContentModal';

// Types
import { TDiningCategory } from '@shared/types/DiningCategory';

// Contexts
import { DynamicDataContext } from '../../../../../../../api/DynamicData';


interface IEditUserModalProps {
    item: TDiningCategory;
    reset?: any;
    id?: number;
}

export default function EditDiningCategory({ id, item }: IEditUserModalProps) {

    const { diningCategories } = useContext(DynamicDataContext);

    let formData: ISimpleForm | undefined;

    if (
        diningCategories.get
    ) {
        formData = {
            id: item.id,
            data: [
                {
                    title: 'Name',
                    key: 'name',
                    value: item.name,
                    inputType: 'string'
                },
            ],
            requestType: 'update',
            requestHandler: {
                target: 'update_menu_category',
                params: [
                    {
                        key: 'menu_type',
                        value: 'dining',
                    },
                    {
                        key: 'category_id',
                        value: item.id
                    }
                ]
            },
            responseHandler: diningCategories.set,
            responseType: 'array',
        }
    }

    return (
        <>
            {formData &&
                <ContentModal headline={`Editing ${item.name}`} id={id}>
                    <SimpleForm formData={formData}/>
                </ContentModal>
            }
        </>
    )
}