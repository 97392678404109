import { useState, useMemo, useContext } from 'react';

// Contexts
import { UIContext } from '../../../../api/UI';

// Components
import Toggle from '../../Toggle/Toggle';
import Button from '../../Button/Button';
import MultiToggle from './../../../../components/common/MultiToggle/MultiToggle';
import MultiToggleOption from './../../../../components/common/MultiToggle/MultiToggleOption';
import ServerRequest from '../../../../ServerRequest';
import Spinner from './../../../../components/common/AnimatedIcon/Spinner';
import InstructionsEditor from './../../../../components/common/Instructions/InstructionsEditor/InstructionsEditor';
import LabelEditor from './../../../../components/common/Labels/LabelEditor/LabelEditor';

// Interfaces
import type { ISimpleForm, IFormUnit, IFormUnitOption } from '../SimpleForm';

// Icons
import resetIcon from './../../../../assets/icons/reset-small-white.png'

// Tools
import Time from '../../../../utils/time';

interface IFormProps extends ISimpleForm {
    reset: () => void;
}

export default function Form(props: IFormProps) {
    const {
        modals,
        notifications
    } = useContext(UIContext);

    const width = props.styleOptions?.width || 'space-between';

    const initialData = useMemo(() => {
        return props.data.map(u => ({
            ...u,
            inputType: u.inputType === 'string' ? 'text' : u.inputType
        }));
    }, [props.data]);

    const ALLOWED_FILE_TYPES = ['png', 'jpg', 'jpeg', 'webp'];

    const [ data, setData ] = useState<IFormUnit[]>(initialData);
    const [ imagePreviews, setImagePreviews ] = useState<any>({});
    const [ imageLabels, setImageLabels ] = useState<any>({});
    const [ isSaving, setIsSaving ] = useState(false);

    function handleChange(index: number, value: string | number) {
        setData(prev => {
            const newData = [...prev];
            newData[index].value = value;
            return [...newData];
        });
    }

    function handleNumberChange(index: number, value: string | number) {
        const filteredValue = value.toString().replace(/[^\d]/g, '');

        if (filteredValue !== value.toString()) {
            return;
        }

        if (parseInt(filteredValue) > 9999999) {
            return;
        }

        setData(prev => {
            const newData = [...prev];
            newData[index].value = filteredValue;
            return [...newData];
        });
    }

    function handleBooleanChange(index: number, value: boolean) {
        setData(prev => {
            const newData = [...prev];
            newData[index].value = !value;
            return [...newData];
        });
    }

    function handleOptionChange(index: number, option: IFormUnitOption) {
        const value = option.value;

        setData(prev => {
            const newData = [...prev];
            newData[index].value = value;
            return [...newData];
        });
    }

    function handleFileChange(index: number, value: File | undefined) {
        if (!value) return;

        let errorLabel: any = null;

        if (!ALLOWED_FILE_TYPES.includes(value.type.replace('image/', '').toLowerCase())) { 
            errorLabel = 'File not supported.';
        } else if (value.size > 2097152) { 
            errorLabel = 'File too large.';
        }

        if (errorLabel) {
            setImageLabels((prev: any) => {
                const newPreviews: any = {};
                Object.keys(prev).forEach(key => {
                    newPreviews[key] = prev[key]
                });
    
                newPreviews[index] = {
                    label: errorLabel,
                    isError: true
                }
    
                return newPreviews;
            });

            return;
        }

        setData(prev => {
            const newData = [...prev];
            newData[index].value = value;
            return [...newData];
        });

        setImagePreviews((prev: any) => {
            const newPreviews: any = {};
            Object.keys(prev).forEach(key => {
                newPreviews[key] = prev[key]
            });

            const url = URL.createObjectURL(value);
            newPreviews[index] = url;

            return newPreviews;
        });

        setImageLabels((prev: any) => {
            const newPreviews: any = {};
            Object.keys(prev).forEach(key => {
                newPreviews[key] = prev[key]
            });

            newPreviews[index] = {
                label: value.name,
                isError: false
            };

            return newPreviews;
        });
    }

    function getFieldsToUpdate() {
        const all = Object.values(data)
        .filter((unit, index) => unit.value !== props.data[index].value)
        .map(unit => [unit.key, unit.value]);

        let fields: any[] = [];
        let files: any[] = [];

        all.forEach(field => {
            if (typeof field[1] === 'object') {
                files.push(field);
            } else {
                fields.push(field);
            }
        })

        return { all, fields, files };
    }

    function getCreationObject() {
        let object: any = {}

        data.forEach(unit => {
            object[unit.key] = unit.value
        });

        return object;
    }

    async function create() {
        const creationObject = getCreationObject();
        const httpData: any = {
            data: creationObject
        }

        if ((props.requestHandler.params?.length ?? 0) > 0) {
            props.requestHandler.params?.forEach(param => {
                httpData[param.key] = param.value;
            });
        }
        
        const result = await ServerRequest.post(props.requestHandler.target, httpData);

        if (result.status === 200) {
            props.responseHandler(result.data.data);
            modals.closeCurrent();
            notifications.add({
                type: 'confirmation',
                title: 'Success',
                content: `Item successfully created!`
            });
        } else {
            notifications.add({
                type: 'error',
                title: 'Error',
                content: `${result.data}`
            });
        }
    }

    async function update() {
        const { fields, files } = getFieldsToUpdate();
        const httpData: any = {
            fieldsToUpdate: fields
        }
    
        if ((props.requestHandler.params?.length ?? 0) > 0) {
            props.requestHandler.params?.forEach(param => {
                httpData[param.key] = param.value;
            });
        }

        
        setIsSaving(true);
        try {
            if (files.length > 0 || !files) {
                const uploadPromises = files.map(file => {
                    
                    const targetFolder = initialData.find(data => data.key === file[0])?.targetFolder ?? 'target_folder_missing';

                    return ServerRequest.uploadImage(file[1] as File, targetFolder, props.id!.toString())
                        .then((response: any) => {
                            const url = response.data.image_url.replaceAll('\n', '');
                            return [file[0], url];
                        });
                });
        
                // Wait for all file uploads to complete and update fields
                const urls = await Promise.all(uploadPromises);
                urls.forEach(url => {
                    fields.push(url);
                });
            }
    
            const result = await ServerRequest.post(props.requestHandler.target, { data: httpData });
    
            if (result.status === 200) {

                props.responseHandler((prev: any) => {
                    let newData = props.responseType === 'array' ? [...prev] : {...prev};
    
                    fields.forEach(field => {
                        if (props.responseType === 'array') {
                            const index = newData.findIndex((d: any) => d.id === props.id);
                            if (index !== -1) {
                                newData[index][field[0]] = field[1];
                            }
                        } else {
                            newData[field[0]] = field[1];
                        }
                    })
    
                    return props.responseType === 'array' ? [...newData] : {...newData};
                });
            } else {
                notifications.add({
                    type: 'error',
                    title: 'Error',
                    content: `${result.data}`
                });
            }
        } catch (error) {
            notifications.add({
                type: 'error',
                title: 'Error',
                content: 'Failed to upload files or update data.'
            });
            console.error('Upload or update error:', error);
        }
        
        props.reset();
        setIsSaving(false);
    }    

    function submit() {
        if (props.requestType === 'update') {
            update();
        } else if (props.requestType === 'create') {
            create();
        }
    }

    return (
        <div className='SimpleForm'>
            {width ==='space-between' &&
                <div className='header'>
                    <div className='title'>{props.title}</div>
                    <nav>
                        {_renderResetButton() &&
                            <Button type={_canSubmit() ? 'dark' : 'inactive'} clickEvent={props.reset}>
                                <img src={resetIcon} alt='reset' />
                            </Button>
                        }
                        <Button type={_canSubmit() && !isSaving ? 'constructive' : 'inactive'} clickEvent={submit}>
                            {isSaving && <Spinner />}{props.styleOptions?.submitLabel || 'Save'}
                        </Button>
                    </nav>
                </div>
            }

            <div className='container' style={width === 'center' ? {justifyContent: width} : {}}>
                {width === 'center' &&
                    <div className='header'>
                            <div className='title'>{props.title}</div>
                        <nav className='item nav'>
                            {_renderResetButton() &&
                                <Button type={_canSubmit() ? 'dark' : 'inactive'} clickEvent={props.reset}>
                                    <img src={resetIcon} alt='reset' />
                                </Button>
                            }
                            <Button type={_canSubmit() ? 'constructive' : 'inactive'} clickEvent={submit}>
                                {isSaving && <Spinner />}{props.styleOptions?.submitLabel || 'Save'}
                            </Button>
                        </nav>
                    </div>
                }

                {data?.map((unit, index) => (
                    <>
                    <div className='item' key={`SimpleFormModel${index}`}>
                        <span className='key'>{unit.title ?? unit.key}: </span>

                        <span className='value'>
                           {_unitInputModel(unit, index)}
                        </span>
                    </div>

                    {unit.info && <span className='info'>
                        {unit.info}
                    </span>}

                    {!unit.info && unit.inputType === 'image' && 
                        <span className='info'>
                            Max 2MB. Allowed filetypes: png, jpg, webp.
                        </span>
                    }
                    </>
                ))}
            </div>
        </div>
    )

    function _canSubmit() {
        if (props.requestType === 'create') {
            return true;
        } else if (props.requestType === 'update') {
            return getFieldsToUpdate().all.length > 0;
        }
    }

    function _renderResetButton() {
        return props.requestType === 'update';
    }

    function _truncateText(text: string, maxLength: number): string {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }

        return text;
    }

    function _unitInputModel(unit: IFormUnit, index: number) {
        if (unit.options) {
            return (
                <MultiToggle>
                    {
                        unit.options!.map((option) => (
                            <MultiToggleOption
                                key={`SimpleFormUnitOption${option.name}`}
                                disabled={option.disabled}
                                clickEvent={() => {handleOptionChange(index, option)}}
                                isActive={data[index].value === option.value}
                            >{option.name}</MultiToggleOption>
                        ))
                    }
                </MultiToggle>
            )
        } else if (unit.inputType === 'boolean') {
            return (
                <Toggle 
                    key={`SimpleFormUnit${index}`}
                    value={unit.value} 
                    isDisabled={unit.disabled}
                    clickEvent={() => handleBooleanChange(index, unit.value as boolean)} 
                />
            )
        } else if (unit.inputType === 'image') {
            return (
                <div className='image'>
                    {
                        imagePreviews[index] 
                        ? <img src={imagePreviews[index]} alt='' />
                        : <img src={`${data[index].value as string}?time=${Date.now()}`} alt='' />
                    }
                    <input 
                        id={`ImageUpload${index}`}
                        key={`SimpleFormUnit${index}`}
                        style={{ display: 'none'}}
                        type='file'
                        className={unit.disabled ? 'inactive' : ''}
                        onChange={(event) => {handleFileChange(index, event.target.files?.[0])}}
                    />
                    <label htmlFor={`ImageUpload${index}`} className='dark' style={{ cursor: 'pointer'}}>
                        Browse
                    </label>
                    {
                        imageLabels[index] &&
                        <div className={imageLabels[index].isError ? 'warning' : ''}> 
                            {_truncateText(imageLabels[index].label, 20)}
                        </div>
                    }
                </div>
            )
        } else if (unit.textarea) {
            return (
                <textarea
                    value={unit.value as string}
                    className={unit.disabled ? 'inactive' : ''}
                    onChange={(event) => {handleChange(index, event.target.value)}}
                />
            )
        } else if (unit.instructions) {
            return (
                <InstructionsEditor 
                    instructionsString={unit.value as string}
                    changeEventArgs={[index]}
                    changeEvent={handleChange}
                />
            )
        } else if (unit.labels) {
            return (
                <LabelEditor
                    labelsString={unit.value as string}
                    changeEventArgs={[index]}
                    changeEvent={handleChange}
                />
            )
        }else if (unit.inputType === 'date') {
            return (
                <input 
                    key={`SimpleFormUnit${index}`}
                    type={unit.inputType}
                    value={Time.convertDateFormat(unit.value as string)}
                    className={unit.disabled ? 'inactive' : ''}
                    onChange={(event) => {handleChange(index, event.target.value)}}
                />
            )
        } else if (unit.inputType === 'number') {
            return (
                <input 
                    key={`SimpleFormUnit${index}`}
                    type={'string'}
                    value={unit.value as string | number}
                    className={unit.disabled ? 'inactive' : ''}
                    onChange={(event) => {handleNumberChange(index, event.target.value)}}
                />
            )
        } else {
            return (
                <input 
                    key={`SimpleFormUnit${index}`}
                    type={unit.inputType}
                    value={unit.value as string | number}
                    className={unit.disabled ? 'inactive' : ''}
                    onChange={(event) => {handleChange(index, event.target.value)}}
                />
            )
        }
    }
}