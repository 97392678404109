import MultiToggle from './../../../../components/common/MultiToggle/MultiToggle';
import MultiToggleOption from './../../../../components/common/MultiToggle/MultiToggleOption';
import React, { useState } from 'react';

// Components
import Venue from './components/Venue/Venue';
import Staff from './components/Staff/Staff';
import DiningMenu from './components/Menu/DiningMenu/DiningMenu';
import ServiceMenu from './components/Menu/ServiceMenu/ServiceMenu';

// Utils
import uuid from 'react-uuid';

const options = [
    {name: 'Venue', component: <Venue />},
    {name: 'Staff', component: <Staff />},
    {name: 'Dining Menu', component: <DiningMenu /> },
    {name: 'Service Menu', component: <ServiceMenu />},
];

export default function Management() {

    const [ selectedOption, setSelectedOption ] = useState(0);

    const [ optionKeys, setOptionKeys ] = useState(options.map(() => uuid()));

    function reset(id: number) {
        setOptionKeys(options.map(() => uuid()));
    }

    return (
        <div className='Profile'>
            <div className='top-nav'>
                <MultiToggle>
                    {
                        options.map((option, index) => (
                            <MultiToggleOption 
                                key={`ProfileOption${optionKeys[index]}`}
                                clickEvent={() => {setSelectedOption(index)}} 
                                isActive={index === selectedOption} type='large'
                            >{option.name}</MultiToggleOption>
                        ))
                    }
                </MultiToggle>
            </div>

            <div className='canvas'>
                {React.cloneElement(options[selectedOption].component, { key: optionKeys[selectedOption] , reset: () => {reset(selectedOption)} })}
            </div>
        </div>
    )
}