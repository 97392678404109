import { useContext } from 'react';

// Contexts
import { UIContext } from '../../../../api/UI';
import { DynamicDataContext } from '../../../../api/DynamicData';

// Components
import Button from '../../../../components/common/Button/Button';
import ConfirmModal from '../../../../components/common/Modal/ConfirmModal';

// Images
import aboutImage from '../../../../assets/icons/info.png';
import coconutImage from '../../../../assets/icons/coconut-small.png';
import vtabImage from '../../../../assets/icons/tab.png';
import logoutImage from '../../../../assets/icons/logout.png';
import cookieImage from '../../../../assets/icons/cookie.png';
import heartImage from './../../../../assets/icons/heart.png';

// Types
import { TCharacter } from '@shared/types/Character';
import { IView } from '../../Dashboard';

// Utils
import ServerRequest from '../../../../ServerRequest';

interface NavigationProps {
    selectedView: number;
    setSelectedView: React.Dispatch<React.SetStateAction<number>>;
    activeCharacter: TCharacter | null;
    views: IView[];
    closeOnNavigate?: boolean;
    setDisplayOverlay?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Navigation({
    selectedView,
    setSelectedView,
    activeCharacter,
    views,
    closeOnNavigate,
    setDisplayOverlay
}: NavigationProps) {
    const {
        modals
    } = useContext(UIContext); 

    const {
        user
    } = useContext(DynamicDataContext);

    function internalRedirect(route: string, newTab: boolean = false) {
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/${route}/`;

        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        } 
    } 

    function redirect(url: string, newTab: boolean = false) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        } 
    }

    function handleSetView(index: number) {
        setSelectedView(index);

        if (closeOnNavigate && setDisplayOverlay) {
            setDisplayOverlay(false);
        }
    }

    function getHasActiveCharacter() {
        if (!activeCharacter) return false;
        if (!activeCharacter.is_enabled) return false;

        return true;
    }

    function handleLogoutModal() {
        modals.add(<ConfirmModal 
            headline='Gode bey'
            message="Are you sure you want to log out?"
            confirmMessage='Log out'
            callback={() => {ServerRequest.logout()}}
        />);
    }

    return (
        <div className='Navigation'>
            <nav>
                <nav>
                    <section>
                        <div className='title'>
                            Kiwi by Enhasa
                        </div>
                        <div className='content'>
                            {
                                views.map((view, index) => (
                                    <Button 
                                        key={`View${index}`}
                                        type={index === selectedView ? 'dark' : 'neutral'}
                                        className='left'
                                        clickEvent={() => {handleSetView(index)}}>
                                        {view.image}
                                        {view.title}
                                    </Button>
                                ))
                            }
                        </div>
                    </section>

                    <section>
                        <div className='title'>
                            External Links
                        </div>
                        <div className='content'>
                            <Button 
                                type='neutral'
                                className='left' 
                                clickEvent={() => {internalRedirect('about', true)}}>
                                <img src={aboutImage} alt='' />
                                About
                            </Button>
                            <Button type='neutral' className='left' clickEvent={() => {internalRedirect('legal', true)}}>
                                <img src={cookieImage} alt='' />
                                Privacy & Cookies
                            </Button>
                            <Button type='neutral' className='left' clickEvent={() => {redirect('https://ko-fi.com/enhasa', true)}}>
                                <img src={heartImage} alt='' />
                                Donations
                            </Button>
                        </div>
                    </section>
                </nav>

                <section>
                    <div className='title'>
                        Apps
                    </div>
                    <div className='content'>
                        
                        {
                            !getHasActiveCharacter() &&
                            <div className='info error'>
                                Cannot use Coconut without an active character.
                            </div>
                        } 
                    
                        <Button 
                            type={`constructive`}
                            className='left'
                            inactive={!getHasActiveCharacter()}
                            clickEvent={() => {internalRedirect('coconut')}}
                        >
                            <img src={coconutImage} alt='Coconut' />
                            Coconut
                        </Button>

                        <Button 
                            type='dark'
                            className='left'
                            clickEvent={() => {window.open('https://vtab.info/', '_blank')}}
                        >
                            <img src={vtabImage} alt='V-Tab' />
                            vTab
                        </Button>
                    </div>

                    <div className='title' />
                    <div className='content'>
                        Logged in as: {user.get?.username}
                        <Button 
                            type='destructive'
                            className='left'
                            clickEvent={handleLogoutModal}
                        >
                            <img src={logoutImage} alt='Log out' />
                            Log out
                        </Button>
                    </div>
                </section>
            </nav>
        </div>
    )
}