import { useState } from "react"

// Components
import CloseButton from "./../Button/CloseButton/CloseButton";

enum LocalStorageKeys {
    CookiesAccepted = 'isCookiePolicyAccepted',
}

export default function CookieBanner() {

    const [ isCookiePolicyAccepted, setIsCookiePolicyAccepted] = useState(getIsAccepted());

    function getIsAccepted() {
        const result = localStorage.getItem(LocalStorageKeys.CookiesAccepted);

        return result === 'true' ? true : false;
    }

    function toggle() {
        const current = isCookiePolicyAccepted;
        
        localStorage.setItem(LocalStorageKeys.CookiesAccepted, !current ? 'true' : 'false');
        setIsCookiePolicyAccepted(!current);
    }

    return (
        <div className='CookieBanner'>
            {!isCookiePolicyAccepted &&
                <div className='wrapper'>
                <span>By using this site you agree to <a href='/legal'>our usage of cookies</a>.</span>
                <CloseButton clickEvent={toggle}/>
                </div>
            }
        </div>
    )
}