import { useState } from 'react';

import Button from './Button';
import trashcanWhite from './../../../assets/icons/trashcan-white.png';

interface IDeleteButtonProps {
    clickEvent: any; 
    inactive?: boolean;
}


export default function DeleteButton({
    clickEvent, 
    inactive
}: IDeleteButtonProps) {

    const [ isHovered, setIsHovered ] = useState(false);

    function handleHover(state: boolean) {
        setIsHovered(state);
    }

    return (
        <Button 
            type={isHovered ? 'destructive' : 'neutral'}
            handleHover={handleHover}
            clickEvent={clickEvent}
            inactive={inactive}
        >
        <img src={trashcanWhite} alt='Delete'/>
        </Button>
    )
}