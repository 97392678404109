interface IDropDown {
    children?: any;
    onChangeEvent?: (T: any) => any;
    value: string;
}

export default function Dropdown(props: IDropDown) {

    const {
        children, 
        onChangeEvent, 
        value
    } = props;

    return(
        <select className='Dropdown' value={value} onChange={onChangeEvent} disabled={!onChangeEvent}>
            {children}
        </select>
    )
}