import ServerRequest from './ServerRequest';

export default class Cookies {
    private static _tokenName = 'auth_token';

    public static async init() {
        const res = await ServerRequest.getEnvironment();
        const tokenName = `auth_token_${res.data}`;

        this._tokenName = tokenName;
    }

    public static getLoginToken() {
        const result = this.getCookie(this._tokenName);
        
        if(result) {
            try {
                const tokenId = JSON.parse(result).token.id;

                if (!tokenId) {
                    this.deleteLoginCookie();
                    return result;
                }

                return tokenId;
            } catch(err) {
                console.error(err);
                this.deleteLoginCookie();
            }
        }
        return result;
    }

    public static setCookie(token: string) {
        document.cookie = token;
    }

    public static deleteLoginCookie() {
        this.deleteCookie(this._tokenName)
    }

    private static deleteCookie(name: string) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    }

    private static getCookie(name: string): string | null {
        const nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }

}