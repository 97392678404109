import { useContext } from 'react';

// Components
import ContentModal from '../../../../../../../components/common/Modal/ContentModal';
import SimpleForm, { type ISimpleForm } from '../../../../../../../components/common/SimpleForm/SimpleForm';

// Types
import { TDiningItem } from '@shared/types/DiningItem';

// Contexts
import { DynamicDataContext } from '../../../../../../../api/DynamicData';

interface IEditDiningItemProps {
    id?: number;
    item: TDiningItem;
}

export default function EditDiningItem({ id, item }: IEditDiningItemProps) {

    const { diningMenu } = useContext(DynamicDataContext);

    const formData: ISimpleForm = {
        id: item.id,
        data: [
            {
                title: 'Available',
                key: 'is_available',
                value: item.is_available,
                inputType: typeof item.is_available
            },
            {
                title: 'Name',
                key: 'name',
                value: item.name,
                inputType: typeof item.name
            },
            {
                title: 'Image',
                key: 'image_url',
                value: item.image_url ?? '',
                inputType: 'image',
                targetFolder: 'dining_items'
            },
            {
                title: 'Price',
                key: 'price',
                value: item.price,
                inputType: typeof item.price
            },
            {
                title: 'Item to deliver',
                key: 'item',
                value: item.item,
                inputType: typeof item.item
            },
            {
                title: 'Description',
                key: 'description',
                value: item.description,
                inputType: typeof item.description,
                textarea: true
            },
            {
                title: 'Ingredients',
                key: 'ingredients',
                value: item.ingredients,
                inputType: typeof item.ingredients,
                labels: true,
            },
            {
                title: 'Instructions',
                key: 'creation_text',
                value: item.creation_text,
                inputType: typeof item.creation_text,
                instructions: true
            }
        ],
        requestType: 'update',
        requestHandler: {
            target: 'update_menu_item',
            params: [
                {
                    key: 'item_id',
                    value: item.id
                },
                {
                    key: 'menu_type',
                    value: 'dining'
                }
            ]
        },
        responseHandler: diningMenu.set,
        responseType: 'array'
    }

    return (
        <ContentModal headline={item.name} id={id}>
            <SimpleForm formData={formData}/>
        </ContentModal>
    )
}