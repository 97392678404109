import { useState, useContext } from "react";

// Componentns
import Button from '../Button/Button';
import Modal from "./Modal";

// Contexts
import { UIContext } from "../../../api/UI";

export type TSafetyConfirmModal = {
    id?: number;
    headline: string;
    message?: string;
    safetyMessage: string;
    safetyPrompt: string;
    confirmMessage?: string;
    cancelMessage?: string;
    callback: Function;
}

export default function SafetyConfirmModal({
    id,
    headline,
    message,
    safetyMessage,
    safetyPrompt,
    confirmMessage = 'Confirm',
    cancelMessage = 'Cancel',
    callback
}: TSafetyConfirmModal) {
    const { modals } = useContext(UIContext);

    const [ safetyInput, setSafetyInput ] = useState<string>('');

    function handleSafetyInput(event: any) {
        setSafetyInput(event.target.value);
    }

    function handleConfirm() {
        callback();
        modals.closeCurrent();
    }

    function handleCancel() {
        modals.closeCurrent();
    }

    return(
        <Modal
            id={id}
            headline={headline}
            message={message}
            >
            <div className='safety-message'>{safetyMessage}</div>
            <div className='safety-prompt'>
                <input type='text' onChange={handleSafetyInput} />
            </div>
            <nav className='options'>
                <Button 
                    type={`constructive`}
                    inactive={safetyInput !== safetyPrompt}
                    clickEvent={handleConfirm}
                >
                    {confirmMessage}
                </Button>

                <Button 
                    type='destructive'
                    clickEvent={handleCancel}
                >
                    {cancelMessage}
                </Button>
            </nav>
        </Modal>
    )
}