import { useContext } from 'react';

// Components
import ContentModal from '../../../../../../../components/common/Modal/ContentModal';
import SimpleForm, { type ISimpleForm } from '../../../../../../../components/common/SimpleForm/SimpleForm';

// Types
import { TServiceItem } from '@shared/types/ServiceItem';

// Contexts
import { DynamicDataContext } from '../../../../../../../api/DynamicData';

interface IEditServiceItemProps {
    id?: number;
    item: TServiceItem;
}

export default function EditServiceItem({ id, item }: IEditServiceItemProps) {

    const { serviceMenu } = useContext(DynamicDataContext);

    const formData: ISimpleForm = {
        id: item.id,
        data: [
            {
                title: 'Available',
                key: 'is_available',
                value: item.is_available,
                inputType: typeof item.is_available
            },
            {
                title: 'Name',
                key: 'name',
                value: item.name,
                inputType: typeof item.name
            },
            {
                title: 'Price',
                key: 'price',
                value: item.price,
                inputType: typeof item.price
            },
            {
                title: 'Interval (minutes)',
                key: 'minute_interval',
                info: 'Leave at 0 for one-time purchases.',
                value: item.minute_interval,
                inputType: 'number'
            },
            {
                title: 'Description',
                key: 'description',
                value: item.description,
                inputType: typeof item.description,
                textarea: true
            },
        ],
        requestType: 'update',
        requestHandler: {
            target: 'update_menu_item',
            params: [
                {
                    key: 'item_id',
                    value: item.id
                },
                {
                    key: 'menu_type',
                    value: 'service'
                }
            ]
        },
        responseHandler: serviceMenu.set,
        responseType: 'array'
    }

    return (
        <ContentModal headline={item.name} id={id}>
            <SimpleForm formData={formData}/>
        </ContentModal>
    )
}