import { useState } from 'react';
import ServerRequest from '../ServerRequest';

// Types
import { TReceipt } from '@shared/types/Receipt';

function getDateDaysAgo(daysBack: number): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - daysBack);

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export interface IUseReceipts {
    get: TReceipt[] | null;
    refresh: () => void;
}

export default function useReceipts(): IUseReceipts {
    const [ receipts, setReceipts ] = useState<TReceipt[] | null>(null);

    const DAYS_BACK_IN_TIME = 180;

    async function refresh() {
        const data = {
            max_date: getDateDaysAgo(DAYS_BACK_IN_TIME),
        }
        setReceipts((await ServerRequest.post('receipts', data)).data);
    }

    return{
        get: receipts,
        refresh: refresh
    }
}