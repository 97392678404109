interface IIconButtonProps {
    clickEvent: () => any;
    type: string;
    children: any;
}

export default function IconButton ({ children, clickEvent, type}: IIconButtonProps) {

    return (
        <button className={`IconButton ${type}`} onClick={clickEvent}>
            {children}
        </button>
    )
}