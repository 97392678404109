import VisitsPerOpening from './VisitorsPerOpening/VisitsPerOpening';
import { useEffect, useMemo, useState, useContext } from 'react';

// Utils
import Time from '../../../../utils/time';

// Contexts
import { DynamicDataContext } from '../../../../api/DynamicData';

// Types
import { TReceipt } from '@shared/types/Receipt';

// Components
import GeneralInfo from '../GeneralInfo/_GeneralInfo';

export type TOpening = {
    date: string;
    month: string;
    visitors: Set<string>;
    receipts: TReceipt[]
}

interface IVenueStats {
    
}

export default function VenueStats(props: IVenueStats) {
    const {
        receipts
    } = useContext(DynamicDataContext);

    const [ openings, setOpenings ] = useState<TOpening[]>([]);

    const parseOpenings = useMemo(() => {
        return function(receipts: TReceipt[]) {
            let openings: TOpening[] = [];
    
            receipts.forEach(receipt => {
                const parsedDate = Time.convertDateFormat(receipt.datetime);
                const opening = openings.find(o => o.date === parsedDate);
    
                if (!opening) {
                    openings.push({
                        date: parsedDate,
                        visitors: new Set([]),
                        month: '',
                        receipts: [receipt]
                    })
                } else {
                    opening.receipts.push(receipt);
                }
            });
    
            openings.forEach((opening, index) => {
                if (opening.receipts.length === 1) {
                    openings.splice(index, 1);
                }
            });

            openings.forEach(opening => {
                opening.receipts.forEach(receipt => {
                    receipt.customers.forEach(customer => {
                        opening.visitors.add(customer);
                    })
                })
            })

            return openings.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        }
    }, []);
    
    useEffect(() => {
        receipts.get && setOpenings(parseOpenings(receipts.get));
    }, [ receipts, parseOpenings ])

    return (
        <>
            <GeneralInfo />
            <div className='VenueStats'>
                <VisitsPerOpening openings={openings} />
            </div>
        </>

    )
}