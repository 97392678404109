// Components
import TextInput from './../Input/TextInput/TextInput';

// Icon
import SearchIcon from './../../../assets/icons/search.png';

interface ISearchFilterProps {
    inputs: any[];
    handleOutput: (T: any) => any;
    searchLabel?: string;
}

export default function SearchFilter({ inputs, handleOutput, searchLabel = 'items' }: ISearchFilterProps) {

    function handleFilter(term: string) {

        const result: any[] = [];

        inputs.forEach((input) => {
            const filteredItems = input.items.filter((i: any) => i[input.filterBy].toLowerCase().includes(term.toLocaleLowerCase()));
            result.push(filteredItems)
        });
    
        handleOutput(result);
    }

    function handleSearchTermChange(term: string) {
        handleFilter(term);
    }

    return (
        <span className='SearchFilter'>
            <img src={SearchIcon} alt='' />
            <TextInput changeEvent={(e) => {handleSearchTermChange(e.target.value)}} placeholder={`Search ${searchLabel}`} />
        </span>
    )
}