import { useState, useMemo, useEffect } from 'react';

// Components
import Button from './../../../common/Button/Button';

interface ILabelEditorProps {
    labelsString: string;
    changeEvent: any;
    changeEventArgs: any[];
}

export default function LabelEditor({ labelsString, changeEvent, changeEventArgs }: ILabelEditorProps) {
    const [ newLabel, setNewLabel ] = useState<string>('');

    const [ isHoveredList, setIsHoveredList ] = 
    useState<boolean[]>(parseStringToLabels(labelsString).map(_ => false));
    
    useEffect(() => {
        setIsHoveredList(parseStringToLabels(labelsString).map(_ => false))
    }, [ labelsString ]);

    function parseLabelsToString(array: string[]) {
        return array.join(',');
    }

    function parseStringToLabels(str: string) {
        return str.split(',');
    }

    const labels = useMemo(() => {
        if (labelsString === null) return [];
        const array = labelsString.split(',');

        return array;
    }, [ labelsString ]);

    function handleAdd() {
        if (newLabel === '') return;

        const labelsCopy = labels.map(l => l);
        labelsCopy.push(newLabel);
        setNewLabel('');

        changeEvent(...changeEventArgs, parseLabelsToString(labelsCopy));
    }

    function handleDelete(index: number) {
        const labelsCopy = labels.map(l => l);
        labelsCopy.splice(index, 1);

        changeEvent(...changeEventArgs, parseLabelsToString(labelsCopy));
    }

    function handleIsHovered(index: number, state: boolean) {
        setIsHoveredList(prev => {
            const copy = prev.map(i => i);
            copy[index] = state;

            return [...copy];
        });
    }

    return (
        <div className='LabelEditor'>
            <div className='labels'>
                {
                    labels.map((label, index) => (
                        <div 
                            className='label' 
                            onMouseOver={() => handleIsHovered(index, true)}
                            onMouseLeave={() => handleIsHovered(index, false)}
                        >
                            {
                                isHoveredList[index] 
                                ? _deleteButton(index, label)
                                : <Button type='neutral' clickEvent={() => {}}>{label}</Button>
                            }
                        </div>
                    ))
                }
            </div>
            <nav>
                <input 
                    type='text' 
                    value={newLabel} 
                    placeholder='Add something...'
                    onChange={(e) => setNewLabel(e.target.value)}
                />
                <Button type='constructive' clickEvent={handleAdd}>Add</Button>
            </nav>
        </div>
    )
    
    function _deleteButton(index: number, label: string) {
        return (
            <Button type='destructive' clickEvent={() => {handleDelete(index)}}><s>{label}</s></Button>
        )
    }
}


