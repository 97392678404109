import { createContext } from 'react';

import useModals, { IUseModals } from '../hooks/useModal';
import useNotifications, { IUseNotifications} from '../hooks/useNotifications';
import useWindowDimensions, { IUseWindowDimensions } from '../hooks/useWindowDimensions';

export interface IUIContext {
    modals: IUseModals;
    notifications: IUseNotifications;
    windowDimensions: IUseWindowDimensions;
}

const UIContext = createContext<IUIContext>({} as IUIContext);

function UIContextProvider({ children }: any) {

    const modals = useModals();
    const notifications = useNotifications();
    const windowDimensions = useWindowDimensions();
    
    return (
        <UIContext.Provider value={{
            modals,
            notifications,
            windowDimensions
        }}>
            {children}
        </UIContext.Provider>
    )    
}

export { UIContextProvider, UIContext };