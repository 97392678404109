import { useState } from 'react';

// Types
import { TDiningCategory } from '@shared/types/DiningCategory';

export interface IUseDiningCategories {
    get: TDiningCategory[] | null;
    add: (T: TDiningCategory) => void;
    getItemById: (T: number) => TDiningCategory | undefined;
    set: React.Dispatch<React.SetStateAction<TDiningCategory[]>>
    remove: (T: number) => void;
    fill: (T: TDiningCategory[]) => void;
}

export default function useDiningCategories(): IUseDiningCategories {
    const [ diningCategories, setDiningCategories ] = useState<TDiningCategory[]>([]);

    function fill(data: TDiningCategory[]) {
        setDiningCategories(data);
    }

    function add(item: TDiningCategory) {
        setDiningCategories(prev => ([...prev, item]))
    }

    function remove(itemId: number) {
        setDiningCategories(prev => prev.filter(i => i.id !== itemId));
    }

    function getItemById(id: number) {
        return diningCategories.find(i => i.id === id);
    }

    return {
        get: diningCategories,
        add,
        getItemById,
        set: setDiningCategories,
        remove,
        fill,
    }
}