import { useContext, useEffect, useRef, useLayoutEffect } from "react";
import { TNotification } from "../../../../hooks/useNotifications";

// Animations
import gsap from "gsap";
import animations from "../../../../utils/animations";

// Contexts
import { UIContext } from "../../../../api/UI";

interface INotificationProps {
    id: number;
    notification: TNotification
}

export default function Notification({ id, notification }: INotificationProps) {
    const { notifications } = useContext(UIContext);
    const ref = useRef(null);

    function handleClose(index: number) {
        notifications.remove(index);
    }

    useLayoutEffect(() => {
        const timeline = gsap.timeline();

        timeline.to(ref.current, animations.slideIn, 0);

        return () => {
            timeline.kill();
        }
    }, []);

    useEffect(() => {
        if (id === undefined) return;
        
        if (!notifications.lifeSupportList.includes(id)) {
            const timeline = gsap.timeline();
            timeline.to(ref.current, animations.slideOut, 0);

            setTimeout(()=> {
                notifications.kill(id);
            }, animations.fadeOut.duration * 1500);
        }
    }, [ id, notifications ])

    return (
        <div className={`notification ${notification.type}`} key={`Notification${id}`} ref={ref}>
            <div className='title-bar'>
                <span className='title'>
                    {notification.title}
                </span>
                <button className='close-button' onClick={() => handleClose(id)}>X</button>
            </div>
            <div className='content'>{notification.content}</div>
        </div>
    )
}