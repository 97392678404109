import { useContext } from 'react';

// Components
import SimpleForm, { type ISimpleForm } from './../../../../../../../../../components/common/SimpleForm/SimpleForm';
import ContentModal from './../../../../../../../../../components/common/Modal/ContentModal';

// Contexts
import { DynamicDataContext } from '../../../../../../../../../api/DynamicData';

interface ICreateUserModal {
    id?: number;
    refresh?: any;
}

export default function CreateUserModal({ id }: ICreateUserModal) {
    const { user, users } = useContext(DynamicDataContext);

    let formData: ISimpleForm | undefined;

    if (
        user.get?.realm_id
    ) {
        formData = {
            styleOptions: {
                submitLabel: 'Create'
            },
            data: [
                {
                    title: 'Username',
                    key: 'username',
                    value: 'newuser',
                    inputType: 'string'
                },
                {
                    title: 'Permission',
                    key: 'access_level',
                    value: 1,
                    inputType: 'number',
                    options: [
                        {
                            name: 'Guest',
                            value: 1
                        },
                        {
                            name: 'User',
                            value: 2
                        },
                        {
                            name: 'Moderator',
                            value: 3
                        },
                        {
                            name: 'Admin',
                            value: 4
                        },
                        {
                            name: 'Owner',
                            value: 5
                        }
                    ]
                },
            ],
            requestType: 'create',
            requestHandler: {
                target: 'create_user',
                params: [
                    {key: 'realm_id', value: user.get?.realm_id}
                ]
            },
            responseHandler: users.add,
            responseType: 'object'
        }
    }

    return (
        <>
            {formData &&
                <ContentModal headline={'New User'} id={id}>
                    <SimpleForm
                        formData={formData}
                    />
                </ContentModal>
            }
        </>
    )
}
