import { useContext } from "react"
import Notification from "./Notification";

//Contexts
import { UIContext } from "../../../../api/UI";

export default function NotificationManager() {

    const {
        notifications
    } = useContext(UIContext);

    return (
        <div className='NotificationManager'>
            {
                notifications.get.map((notification, index) => (
                    <Notification 
                        id={index}
                        notification={notification}
                    />
                ))
            }
        </div>
    )
}