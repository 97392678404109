import LineGraph from "./../../../../../components/common/LineGraph/LineGraph";
import { TOpening } from "../VenueStats"

interface IVisitsPerOpening {
    openings: TOpening[]
}

export default function VisitsPerOpening(props: IVisitsPerOpening) {
    const { openings } = props;

    const labels = openings.map(o => o.date);
    const datasets = [{
        label: 'Visitors last 180 days.',
        data: openings.map(o => o.visitors.size)
    }];

    return (
        <div className='VisitsPerOpening'>
            <LineGraph labels={labels} datasets={datasets} />
        </div>
    )
}