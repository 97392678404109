import { ReactNode, useContext, useEffect, useRef, useLayoutEffect } from "react";

// Components
import CloseButton from './../../../components/common/Button/CloseButton/CloseButton';

// Contexts
import { UIContext } from "../../../api/UI";

// Animations
import { gsap } from 'gsap';
import animations from "../../../utils/animations";

interface IModal {
    id?: number;
    headline: string;
    message?: string;
    children?: ReactNode;
    closable?: boolean;
}

export default function Modal({
    id,
    headline,
    message,
    children,
    closable = true
}: IModal) {
    const { modals } = useContext(UIContext);
    const ref = useRef(null);

    function handleModalClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
    }

    function handleClose() {
        modals.closeCurrent();
    }

    useLayoutEffect(() => {
        const timeline = gsap.timeline();

        timeline.to(ref.current, animations.slideIn, 0);

        return () => {
            timeline.kill();
        }
    }, []);
    
    useEffect(() => {

        if (id === undefined) return;
        
        if (!modals.lifeSupportList.includes(id)) {
            const timeline = gsap.timeline();
            timeline.to(ref.current, animations.slideOut, 0);

            setTimeout(()=> {
                modals.kill(id);
            }, animations.fadeOut.duration * 1500);
        }
    }, [id, modals, modals.lifeSupportList])

    return (
        <div className='Modal' onClick={handleModalClick} ref={ref}>
            <div className='headline' style={{justifyContent: `${closable ? 'space-between' : 'center'}`}}>
                <span>{headline}</span>
                {closable && <CloseButton clickEvent={handleClose}/>}
            </div>
            <div className='message'>{message}</div>
            <div className='body'>
                {children}
            </div>
        </div>
    )
}