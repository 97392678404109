import { useContext, useMemo, useState } from "react";

// Contexts
import { DynamicDataContext } from "../../../../../../../api/DynamicData";
import { UIContext } from "../../../../../../../api/UI";

// Components
import ServiceItem from "./ServiceItem";
import { TServiceItem } from "@shared/types/ServiceItem";
import SearchFilter from '../../../../../../../components/common/SearchFilter/SearchFilter';
import Button from '../../../../../../../components/common/Button/Button';
import DeleteButton from '../../../../../../../components/common/Button/_DeleteButton';

// Types
import { TServiceCategory } from "@shared/types/ServiceCategory";

// Utils
import ServerRequest from "../../../../../../../ServerRequest";

// Modals
import CreateServiceItem from "./CreateServiceItem";
import EditServiceCategory from "./EditServiceCategory";
import CreateServiceCategory from "./CreateServiceCategory";
import ConfirmModal from "../../../../../../../components/common/Modal/ConfirmModal";

export default function ServiceMenu({ reset }: any) {

    const { serviceCategories, serviceMenu } = useContext(DynamicDataContext);
    const { modals, notifications } = useContext(UIContext);

    const [ filteredItems, setFilteredItems ] = useState(serviceMenu.get ?? []);

    const categories = useMemo(() => {
        let categories: any = {};

        serviceCategories.get?.forEach(category => {
            categories[category.id] = {
                name: category.name,
                id: category.id,
                items: []
            };
        });

        filteredItems?.forEach(item => {
            categories[item.category_id]?.items.push(item)
        });

        return categories
    }, [ serviceCategories.get, filteredItems ]);

    function handleFilter(output: any) {
        const [ itemResult ] = output;
        setFilteredItems(itemResult);
    }

    function handleCreateItem(categoryId: number, categoryName: string) {
        modals.add(<CreateServiceItem 
            reset={reset}
            categoryId={categoryId}
            categoryName={categoryName}
        />)
    }

    function handleCreateCategory() {
        modals.add(<CreateServiceCategory 
            reset={reset}
        />)
    }

    async function removeCategory(item: TServiceCategory) {
        const result = await ServerRequest.post('delete_menu_category', {item_id: item.id, menu_type: 'service'});

        if (result.status === 200) {
            serviceCategories.remove(item.id);
            reset && reset();

            notifications.add({
                type: 'confirmation',
                content: `${item.name} has been deleted.`,
                title: 'Category Deleted'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    function handleRemoveCategory(category?: TServiceCategory) {
        if (!category) return;

        modals.add(
            <ConfirmModal 
                headline='Delete category'
                message={`Are you sure you want to delete ${category.name}?`}
                callback={() => {removeCategory(category)}}
            />
        )
    }

    function handleEditCategory(category?: TServiceCategory) {
        if (!category) return;

        modals.add(<EditServiceCategory
            reset={reset}
            item={category}
        />)
    }

    const itemsToFilter = [
        {
            name: 'Items',
            items: serviceMenu.get,
            filterBy: 'name'
        }
    ];

    return (
        <div className='Menu'>
            <div className='top-nav'>
                <SearchFilter inputs={itemsToFilter} handleOutput={handleFilter} searchLabel={'services'} />
                <Button type='constructive' clickEvent={handleCreateCategory}>Add Category</Button>
            </div>

            <div className='categories'>
                {
                    Object.keys(categories)
                    .filter(categoryId => {
                        if (filteredItems.length !== serviceMenu.get?.length) {
                            return categories[categoryId].items.some((item: any) => filteredItems.includes(item));
                        }

                        return true;
                    })
                    .map(categoryId => (
                        <div className='category' key={categoryId}>
                            <div className='title'> 
                                <span className='name'>{categories[categoryId].name} </span>
                                <nav>
                                    <Button 
                                        type='dark' 
                                        clickEvent={() => {handleEditCategory(serviceCategories.getItemById(parseInt(categoryId)))}}>
                                        Edit
                                    </Button>
                                    <Button 
                                        type='constructive' 
                                        clickEvent={() => {handleCreateItem(parseInt(categoryId), categories[categoryId].name)}}>
                                        Add Item
                                    </Button>
                                    <DeleteButton clickEvent={() => {handleRemoveCategory(serviceCategories.getItemById(parseInt(categoryId)))}} />
                                </nav>
                            </div>
                            <div className='items'>
                            {
                                categories[categoryId].items
                                    .filter((item: any) => filteredItems.includes(item))
                                    .map((item: TServiceItem, index: number) => (
                                        <div className='item' key={`key${index}`}>
                                            <ServiceItem item={item} reset={reset} />
                                        </div>
                                    ))
                            }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}