import { useState, useRef } from 'react';

interface IImageUploadProps {
    setImage: React.Dispatch<React.SetStateAction<File | string | null>>
    setImagePreview: React.Dispatch<React.SetStateAction<string | null >>
}

export default function ImageUpload(props: IImageUploadProps) {
    const {
        setImage,
        setImagePreview,
    } = props;

    const [ fileWarning, setFileWarning ] = useState<null | string>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    function handleChangeImageBuffer(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];

        if (!file) return;
        if (file.size > 2097152) {
            setFileWarning('File size exceeded. Please choose a different image.');
            setImage(null);
            setImagePreview(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        } else {
            setFileWarning(null);
            setImage(file);
            const fileUrl = URL.createObjectURL(file);
            setImagePreview(fileUrl);
        }
    }

    return (
        <div className='ImageUpload'>
            <input 
                type='file' 
                className='file-input' 
                onChange={handleChangeImageBuffer} 
                ref={fileInputRef}
            />
            { fileWarning && 
                <div className='file-warn'> {fileWarning} </div>
            }
        </div>
    )
}