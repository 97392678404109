import { useState } from 'react';
import ServerRequest from '../ServerRequest';

// Types
import { TRealm } from '@shared/types/Realm';

// Utils
import Time from '../utils/time';

export interface IUseRealm {
    get: TRealm | null;
    set: (T: any) => void;
    refresh: () => void;
}

export default function useRealm(): IUseRealm {
    const [ realm, setRealm ] = useState<TRealm | null>(null);

    async function refresh() {
        const data = (await ServerRequest.get("realm")).data.data;

        if (data.founding_date) {
            data.founding_date = Time.convertDateFormat(data.founding_date);
        }

        setRealm(data);
    }

    return {
        get: realm,
        set: setRealm,
        refresh: refresh
    }
}
