import { useContext } from "react"

// Contexts
import { UIContext } from "../../../../api/UI"

export default function ModalManager() {
    const {
        modals
    } = useContext(UIContext);

    const reversedModals = modals.get.slice().reverse();

    function shouldBlur(index: number) {
        if (modals.get.length < 2) return false;

        return index === 0;
    }

    return (
        <>
            {
            modals.get.length > 0 &&
                <div className='ModalManager' onClick={modals.closeCurrent}>

                    {reversedModals.map((modal, index) => (
                        <div 
                            className={`modal-display ${shouldBlur(index) ? 'blurred' : ''}`}
                            key={`Modal${index}`} 
                        >
                            {modal.component}
                        </div>
                    ))}
                </div>
            }
        </>

    )
}