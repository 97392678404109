export default function Legal() {


    return (
        <div className='Legal'>
            <h1>Privacy and Cookie Policy for littlekiwi.app</h1>
            <p>
                Welcome to littlekiwi.app! We are committed to protecting your privacy and ensuring a secure online experience. 
                This document outlines our policies regarding the collection, use, and disclosure of information we receive from users of our site.
            </p>

            <h2>Information We Collect</h2>
            <ul>
                <li><strong>Username and Password:</strong> When you create an account on our website, you are asked to choose a fictional username and a password. These credentials are used exclusively to access your account and are not linked to any real-world identity.</li>
                <li><strong>Fictional Character Data:</strong> You can create and customize fictional characters, including details such as a character name, gender, bio, and profile picture. These elements help personalize your experience and are essential for certain functionalities of our platform.</li>
                <li><strong>Fictional Venue Data:</strong> Users can add and manage data related to roleplaying venues, including the venue name, founding date, website link, logo, banner image, layout details like floors and table locations, as well as menu items and services offered. This information enhances the roleplaying experience and helps users to organize and display their venues effectively.</li>
                <li><strong>Fictional Order Data:</strong> We collect fictional customer names (in-game character names) and details of their fictional orders within the context of Final Fantasy XIV. This data is used for in-game activities such as generating roleplay receipts, organizing which orders should be delivered to which in-game table/customer, and compiling in-game earnings for payouts to staff characters.</li>
            </ul>

            <h2>Use of Cookies</h2>
            <ul>
                <li><strong>Session Cookie:</strong> Our website uses a session cookie that is essential for navigating and using the features of our platform. This cookie is used to authenticate your login details and maintain your session. It does not contain any personal information and is generated through a completely random process.</li>
                <li><strong>Purpose:</strong> This cookie is strictly necessary for the functionality and security of our website. It ensures that you remain logged in and that your session is secure throughout your visit.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <ul>
                <li><strong>Account Management:</strong> We use your username and password to manage your account and provide you with access to our site.</li>
                <li><strong>Security:</strong> We enhance the security of our website through the use of session cookies, which protect against unauthorized access.</li>
                <li><strong>Character Management:</strong> The character data you provide is used to create and manage your fictional character profiles. This information allows for a customized and engaging experience on our platform.</li>
                <li><strong>Venue Management:</strong> The venue data you enter is used to manage and display your venue. This includes organizing layout, managing menu offerings, and facilitating user interaction with venue features.</li>
                <li><strong>Roleplay Order Processing:</strong> Fictional order data are used for in-game purposes, such as managing interactions and transactions between characters, creating an immersive roleplay environment.</li>
            </ul>

            <h2>Data Sharing and Disclosure</h2>
            <p>
                We do not share or sell any information you provide to us with third parties. 
                Your information is used solely for the purposes of maintaining your account and providing the services requested by you.
            </p>

            <h2>Data Retention</h2>
            <p>
                We retain your account information for as long as your account is active. 
                If you choose to delete your account, your information, including your username and password, is permanently removed from our servers.
            </p>

            <h2>Your Rights</h2>
            <p>
                Under the General Data Protection Regulation (GDPR) and other relevant laws, you have certain rights regarding your personal data. 
                These include the right to access, correct, and request the deletion of your data. 
                Given our minimal data collection, the scope of these rights may be limited to the information stored in your account.
            </p>

            <h2>Managing Cookies</h2>
            <p>
                While the session cookie used on our site is essential and cannot be disabled if you wish to use our services, it is important to note that this cookie does not track your browsing activity on other sites nor does it collect or store personal information.
            </p>

            <h2>Changes to This Policy</h2>
            <p>
                We may update this Privacy and Cookie Policy from time to time. 
                Any changes will be posted on this page, and we encourage you to review our policy periodically to stay informed.
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about our privacy practices or this policy, please contact us at <strong>enhaasa@pm.me</strong> or message <strong>_enhasa</strong> on discord.</p>
            
        </div>
    )
}