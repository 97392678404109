import CopyField from './../../../../../../../../../components/common/CopyField/CopyField';

interface IPasswordResetConfirmationModalProps {
    username: string;
    password: string;
}

export default function PasswordResetConfirmationModal({ 
    username, 
    password 
}: IPasswordResetConfirmationModalProps) {
    return (
        <div className='PasswordResetConfirmationModal'>
            The password for <b>{username}</b> has been reset to:
            <CopyField content={password} /> <br />
            The user will be logged out.
        </div>
    )
}