import { useEffect, createContext } from 'react';

// Hooks
import useUser, { IUseUser} from '../hooks/useUser';
import useUsers, { IUseUsers } from '../hooks/useUsers';
import useRealm, { IUseRealm } from '../hooks/useRealm';
import useDiningCategories, { IUseDiningCategories } from '../hooks/useDiningCategories';
import useDiningMenu, { IUseDiningMenu } from '../hooks/useDiningMenu';
import useServiceCategories, { IUseServiceCategories } from '../hooks/useServiceCategories';
import useServiceMenu, { IUseServiceMenu } from '../hooks/useServiceMenu';
import useCharacters, { IUseCharacters } from '../hooks/useCharacters';
import useReceipts, { IUseReceipts } from '../hooks/useReceipts';

// Utils
import Cookies from '../Cookies';
import ServerRequest from '../ServerRequest';

export interface IDynamicDataContext {
    user: IUseUser;
    users: IUseUsers;
    realm: IUseRealm;
    diningCategories: IUseDiningCategories;
    diningMenu: IUseDiningMenu;
    serviceCategories: IUseServiceCategories;
    serviceMenu: IUseServiceMenu
    characters: IUseCharacters;
    receipts: IUseReceipts;
}

const DynamicDataContext = createContext<IDynamicDataContext>({} as IDynamicDataContext);

function DynamicDataContextProvider({ children }: any) {

    const user = useUser();
    const users = useUsers();
    const realm = useRealm();
    const diningCategories = useDiningCategories();
    const diningMenu = useDiningMenu();
    const serviceCategories = useServiceCategories();
    const serviceMenu = useServiceMenu();
    const characters = useCharacters();
    const receipts = useReceipts();
    
    useEffect(() => {
        Cookies.init().then(async () => {
            realm.refresh();
            receipts.refresh();

            const data = (await ServerRequest.post('kiwi_data')).data;

            user.fill(data.user);
            users.fill(data.users);
            diningCategories.fill(data.dining_categories);
            diningMenu.fill(data.dining_items);
            serviceCategories.fill(data.service_categories);
            serviceMenu.fill(data.service_items);
            characters.fill(data.characters);
        });
    }, []);

    return (
        <DynamicDataContext.Provider value={{
            user,
            users,
            realm,
            diningCategories,
            diningMenu,
            serviceCategories,
            serviceMenu,
            characters,
            receipts,
        }}>
            {children}
        </DynamicDataContext.Provider>
    )    
}

export { DynamicDataContextProvider, DynamicDataContext };