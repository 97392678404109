import { useContext } from "react";

// Components
import Button from '../../../../../../../components/common/Button/Button';
import DeleteButton from '../../../../../../../components/common/Button/_DeleteButton';

// Modals
import ConfirmModal from "../../../../../../../components/common/Modal/ConfirmModal";
import EditServiceItem from './EditServiceItem';

// Contexts
import { UIContext } from '../../../../../../../api/UI';
import { DynamicDataContext } from "../../../../../../../api/DynamicData";

// Types
import { TServiceItem } from "@shared/types/ServiceItem";

// Utils
import ServerRequest from "../../../../../../../ServerRequest";

interface IServiceItemProps {
    item: TServiceItem;
    reset?: (T?: any) => any;
}

export default function ServiceItem({ item, reset }: IServiceItemProps) {

    const { modals, notifications } = useContext(UIContext);
    const { serviceMenu } = useContext(DynamicDataContext);

    function handleEdit() {
        modals.add(
            <EditServiceItem item={item} />
        )
    }

    async function remove() {
        const result = await ServerRequest.post('delete_menu_item', {item_id: item.id, menu_type: 'service'});

        if (result.status === 200) {
            serviceMenu.remove(item.id);
            reset && reset();

            notifications.add({
                type: 'confirmation',
                content: `${item.name} has been deleted.`,
                title: 'Item Deleted'
            });
        } else {
            notifications.add({
                type: 'error',
                content: result.data,
                title: 'Error'
            });
        }
    }

    function handleRemove() {
        modals.add(
            <ConfirmModal 
                headline='Delete item'
                message={`Are you sure you want to delete ${item.name}?`}
                callback={remove}
            />
        )
    }

    return (
        <>
            <span>{
                _getItemName(item)
            
            }</span>
            <nav>
                <Button type='dark' clickEvent={handleEdit}>Edit</Button>
                <DeleteButton clickEvent={handleRemove} />
            </nav>
        </>
    )

    function _getItemName(item: TServiceItem) {

        let name = item.name !== '' ? <>{item.name}</> : <i>( Nameless )</i>;

        if (!item.is_available) {
            name = <span className='inactive'>{name}</span>
        } 

        return name;
    }
}