export default function Spinner() {
    return (
    <div className='Spinner'>
        <div className="loadingio-spinner-spinner-36d38ny93kh">
            <div className="ldio-y1lg4s4vfo">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    )
}