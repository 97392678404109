import { useContext } from 'react';

// Components
import SimpleForm, { type ISimpleForm } from './../../../../../../../components/common/SimpleForm/SimpleForm';
import ContentModal from './../../../../../../../components/common/Modal/ContentModal';

// Contexts
import { DynamicDataContext } from '../../../../../../../api/DynamicData';

interface ICreateUserModalProps {
    categoryId: number;
    categoryName: string;
    reset?: any;
    id?: number;
}

export default function CreateDiningItem({ id, categoryId, categoryName, reset }: ICreateUserModalProps) {

    const { diningMenu } = useContext(DynamicDataContext);

    let formData: ISimpleForm | undefined;

    if (
        diningMenu.get
    ) {
        formData = {
            styleOptions: {
                submitLabel: 'Create'
            },
            data: [
                {
                    title: 'Available',
                    key: 'is_available',
                    value: false,
                    inputType: 'boolean'
                },
                {
                    title: 'Name',
                    key: 'name',
                    value: '',
                    inputType: 'string'
                },
                {
                    title: 'Price',
                    key: 'price',
                    value: 0,
                    inputType: 'number'
                },
                {
                    title: 'Item to deliver',
                    key: 'item',
                    value: '',
                    inputType: 'string'
                },
                {
                    title: 'Description',
                    key: 'description',
                    value: '',
                    inputType: 'string',
                    textarea: true
                },
                {
                    title: 'Ingredients',
                    key: 'ingredients',
                    value: '',
                    inputType: 'string',
                    labels: true,
                },
                {
                    title: 'Instructions',
                    key: 'creation_text',
                    value: '',
                    inputType: 'string',
                    instructions: true
                }
            ],
            requestType: 'create',
            requestHandler: {
                target: 'create_menu_item',
                params: [
                    {
                        key: 'menu_type',
                        value: 'dining',
                    },
                    {
                        key: 'category_id',
                        value: categoryId
                    },
                    {
                        key: 'category_name',
                        value: categoryName
                    }
                ]
            },
            responseHandler: (item: any) => {
                diningMenu.add(item);
                reset();
            },
            responseType: 'object'
        }
    }

    return (
        <>
            {formData &&
                <ContentModal headline={`New ${categoryName} Item`} id={id}>
                    <SimpleForm formData={formData}/>
                </ContentModal>
            }
        </>
    )
}