import { useContext } from 'react';

// Contexts
import { UIContext } from '../../../../api/UI';

// Types
import { TCharacter } from '@shared/types/Character';
import { IView } from '../../Dashboard';

// Icons
import MenuIcon from './../../../../assets/icons/menu.png';

// Components
import Button from './../../../../components/common/Button/Button';
import Navigation from '../Navigation/Navigation';

interface SidebarProps {
    selectedView: number;
    setSelectedView: React.Dispatch<React.SetStateAction<number>>;
    activeCharacter: TCharacter | null;
    views: IView[];
    height: string;
    setDisplayOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Sidebar({
    selectedView,
    setSelectedView,
    activeCharacter,
    views,
    height,
    setDisplayOverlay
}: SidebarProps) {

    const { windowDimensions } = useContext(UIContext);

    return (
        <aside className='Sidebar' style={{ height: height }}>

            {
                windowDimensions.width < 800 
                ?   <div className='toggle-overlay'>
                        <Button type='neutral' clickEvent={() => {setDisplayOverlay(true)}}>
                            <img src={MenuIcon} alt='Menu' />
                        </Button>
                    </div>
                :   <Navigation 
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        activeCharacter={activeCharacter}
                        views={views}
                    />
            }
        </aside>
    )
}