import Cookies from "../../Cookies"

export default function Tools() {

    function clearLoginCookie() {
        try {
            Cookies.deleteLoginCookie();
            alert('Login cookie cleared.');
        } catch(err) {
            alert('Browser refused the cookie clear attempt.')
        }

    }

    return (
        <div className='Tools'>
            <button onClick={clearLoginCookie}>Clear Login Cookie</button>
        </div>
    )
}