import MultiToggle from './../../../../components/common/MultiToggle/MultiToggle';
import MultiToggleOption from './../../../../components/common/MultiToggle/MultiToggleOption';
import Character from './components/Character/Character';
import Account from './components/Account/Account';
import { useState } from 'react';

export default function Profile() {

    const [ selectedOption, setSelectedOption ] = useState(0);

    const options = [
        ['Characters', <Character />],
        ['Account', <Account />],
    ];

    return (
        <div className='Profile'>
            <div className='top-nav'>
                <MultiToggle>
                    {
                        options.map((option, index) => (
                            <MultiToggleOption 
                                key={`ProfileOption${index}`}
                                clickEvent={() => {setSelectedOption(index)}} 
                                isActive={index === selectedOption} type='large'
                            >{option[0]}</MultiToggleOption>
                        ))
                    }
                </MultiToggle>
            </div>

            <div className='canvas'>
                {options[selectedOption][1]}
            </div>
        </div>
    )
}