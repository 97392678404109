// Components
import Button from './../../../common/Button/Button';
import DeleteButton from './../../../common/Button/_DeleteButton';

// Icons
import ArrowUpIcon from './../../../../assets/icons/arrow-up-white.png';
import ArrowDownIcon from './../../../../assets/icons/arrow-down-white.png';

interface IInstructionsEditorProps {
    instructionsString: string;
    changeEventArgs: any[];
    changeEvent: any;
}

enum MoveDirection {
    Up,
    Down,
}

type TInstructions = string[];

export function parseStringToInstructions(str: string | null): TInstructions {
    if (str === null) return [];
    let array = str.replaceAll('\n', '').split('[step]');

    return array;
}

export function parseInstructionsToString(instructions: TInstructions) {
    if (instructions.length === 0) return null;
    return instructions.join('[step]');
}

export default function InstructionsEditor({ instructionsString, changeEvent, changeEventArgs }: IInstructionsEditorProps) {
    const instructions = parseStringToInstructions(instructionsString);

    function handleAdd() {
        const newArray = instructions.map(i => i);
        newArray.push(' ');

        const stringifiedInstructions = parseInstructionsToString(newArray);

        changeEvent(...changeEventArgs, stringifiedInstructions)
    }

    function handleDelete(indexToRemove: number) {
        let filteredInstructions = instructions.filter((_, index) => index !== indexToRemove);
        const stringifiedInstructions = parseInstructionsToString(filteredInstructions);

        changeEvent(...changeEventArgs, stringifiedInstructions);
    }

    function handleChange(index: number, newString: string) {
        const instructionsCopy = instructions.map(i => i);
        instructionsCopy[index] = newString;

        const stringifiedInstructions = parseInstructionsToString(instructionsCopy);

        changeEvent(...changeEventArgs, stringifiedInstructions)
    }

    function handleMove(index: number, direction: MoveDirection) {
        if (!_canMove(index, direction)) return;

        const self = instructions[index];
        const targetIndex = direction === MoveDirection.Up ? index -1 : index +1;

        const target = instructions[targetIndex];

        instructions[targetIndex] = self;
        instructions[index] = target;

        const stringifiedInstructions = parseInstructionsToString(instructions);

        changeEvent(...changeEventArgs, stringifiedInstructions)
        }

    return (
        <div className='InstructionsEditor'>
            {
                instructions.map((instruction, index) => (
                    <div className='instruction' key={`Instruction${index}`}>
                        <span className='col'>
                            {index +1}.
                        </span>

                        <span className='col nav'>
                            <textarea 
                                value={instruction}
                                onChange={(e) => {handleChange(index, e.target.value)}}
                            />

                            <Button 
                                type={`neutral`} 
                                inactive={!_canMove(index, MoveDirection.Up)}
                                clickEvent={() => {handleMove(index, MoveDirection.Up)}}
                            >
                                <img src={ArrowUpIcon} alt='' />
                            </Button>
                        
                            <Button 
                                type={`neutral`} 
                                inactive={!_canMove(index, MoveDirection.Down)}
                                clickEvent={() => {handleMove(index, MoveDirection.Down)}}
                            >
                                <img src={ArrowDownIcon} alt='' />
                            </Button>

                            <DeleteButton clickEvent={() => {handleDelete(index)}} />
                        </span>
                    </div>
                ))
            }
            <nav className='bottom-nav'>
                <Button type='constructive' clickEvent={handleAdd}>Add Step</Button>
            </nav>
        </div>
    )

    function _canMove(index: number, direction: MoveDirection) {
        if (direction === MoveDirection.Up) {
            return index-1 >= 0;
        } else if(direction === MoveDirection.Down) {
            return index+1 <= instructions.length-1;
        }
    }
}