import { useContext, useMemo, useState, useEffect, useRef } from "react";
import SimpleForm, { type ISimpleForm } from "../../../../../../../../components/common/SimpleForm/SimpleForm";
import uuid from "react-uuid";

// Types
import { TUser } from "@shared/types/User";

// Contexts
import { DynamicDataContext } from "../../../../../../../../api/DynamicData";
import { UIContext } from "../../../../../../../../api/UI";

// Components
import PermissionsTable from "./PermissionsTable";
import Button from "../../../../../../../../components/common/Button/Button";
import DeleteButton from "../../../../../../../../components/common/Button/_DeleteButton";
import SafetyConfirmModal from "../../../../../../../../components/common/Modal/SafetyConfirmModal";
import SearchFilter from "../../../../../../../../components/common/SearchFilter/SearchFilter";

// Modals
import ContentModal from "../../../../../../../../components/common/Modal/ContentModal";
import CreateUserModal from "./CreateUserModal/CreateUserModal";

// Utils
import ServerRequest from "../../../../../../../../ServerRequest";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal";
import EditCharactersByUser from "./EditCharactersByUser/EditCharactersByUser";

const permissions = [
    {
        name: 'Guest',
        value: 1
    },
    {
        name: 'User',
        value: 2
    },
    {
        name: 'Moderator',
        value: 3
    },
    {
        name: 'Admin',
        value: 4
    },
    {
        name: 'Owner',
        value: 5
    }
]

function getPermissionOptions(user: TUser | null, u: TUser) {
    let options = permissions.map(option => ({
        ...option, 
        disabled: false
    }));

    if (user && user.access_level < 5) {
        options = options.map(option => ({
            ...option,
            disabled: (u.access_level >= user.access_level) || (option.value >= user.access_level)
        }));
    }

    return options;
}

export default function Users({refresh}: any) {
    const { users, characters, user } = useContext(DynamicDataContext);
    const { modals, notifications } = useContext(UIContext);

    function getCanDestructivelyModify(userId: number | undefined) {
        if (!userId) return false;

        const u = users.getUserById(userId);
        if (!u) return false;

        let result = false;
        if (user.get && user.get.access_level) {
            if (user.get.access_level >= 4) {
                if (u.access_level >= user.get.access_level) {
                    result = true;
                }
            }
        }

        return result;
    }

    const formData: ISimpleForm[] | undefined = useMemo(() => {
        return users.get?.map(u => {
            return {
                id: u.id,
                title: u.username,
                styleOptions: {
                    width: 'space-between'
                },
                data: [
                    {
                        title: 'Username',
                        key: 'username',
                        value: u.username,
                        inputType: typeof u.username,
                        disabled: getCanDestructivelyModify(u.id)
                    },
                    {
                        title: 'Permission',
                        key: 'access_level',
                        value: u.access_level,
                        inputType: typeof u.access_level,
                        options: getPermissionOptions(user.get, u)
                    }
                ],
                requestType: 'update',
                requestHandler: {
                    target: 'update_user',
                    params: [
                        {
                            key: 'user_id',
                            value: u.id
                        }
                    ]
                },
                responseHandler: users.set,
                responseType: 'array'
            }
        })
    }, [users.get, users.set, user.get]);

    function getUserById(userId: number | undefined) {
        return users.get?.find(c => c.id === userId);
    }

    function handleCreate() {
        modals.add(<CreateUserModal />)
    }

    function handleDelete(user: TUser | undefined) {

        if (!user) return;

        modals.add(
            <SafetyConfirmModal 
                headline={'Are you sure?'}
                message={
                    'This will delete the user along with all their characters.'
                }
                safetyMessage={'You must type out their username to proceed.'}
                safetyPrompt={user.username}
                callback={async () => {
                    const userCharacters = characters.getCharactersByUserId(user.id)

                    const result = await ServerRequest.post('delete_user', {user_id: user.id})

                    if (result.status === 200) {
                        userCharacters.forEach(character => {characters.remove(character.id)});
                        users.remove(user);
    
                        notifications.add({
                            title: 'User Deleted',
                            type: 'confirmation',
                            content: `${user.username} has been deleted.`,
                        });
    
                        refresh();
                    } else {
                        notifications.add({
                            type: 'error',
                            content: result.data,
                            title: 'Error'
                        })
                    }
                }}
            />
        )
    }

    async function handleResetPassword(user: TUser | undefined) {
        if (!user) return;

        modals.add(
            <ResetPasswordModal user={user} />
        )
    }

    function handlePermissionsTable() {
        modals.add(
            <ContentModal headline='Permissions'>
                <PermissionsTable />
            </ContentModal>
        )
    }

    function handleEditCharactersByUser(user?: TUser) {
        if (user === undefined) return;

        modals.add(
            <ContentModal headline='Characters'>
                <EditCharactersByUser user={user} />
            </ContentModal>
        )
    }

    // This is fucking stupid
    const [ filteredItems, setFilteredItems ] = useState(users.get ?? []);
    const [ filteredItemIds, setFilteredItemIds ] = useState(filteredItems.map(() => uuid()));

    useEffect(() => {
        setFilteredItems(users.get ?? []);
    },[ users.get ]);

    useEffect(() => {
        setFilteredItemIds(filteredItems.map(() => uuid()));
    }, [ filteredItems.length ]);

    function handleFilter(output: any) {
        const [ itemResult ] = output;
        setFilteredItems(itemResult);
    }

    const itemsToFilter = [
        {
            name: 'Users',
            items: users.get, 
            filterBy: 'username'
        },
    ];

    return (
        <div className='Users'>
            <nav className='top-nav'>
                <span>
                    <SearchFilter inputs={itemsToFilter} handleOutput={handleFilter} searchLabel={'users'} />
                </span>
                
                <span>
                    <Button type='dark' clickEvent={handlePermissionsTable}>
                            Permissions Table
                    </Button>
                    <Button type='constructive' clickEvent={handleCreate}>New User</Button>
                </span>
            </nav>
            <div className='staff-list'>
                {
                    filteredItems?.map((fi, index) => (
                        fi &&
                        <div className='staff-item' key={filteredItemIds[index]}>
                            <SimpleForm 
                                formData={formData?.find(item => item.id === fi.id)!}
                            />

                            <nav className='bottom-nav'>
                                <Button clickEvent={() => handleEditCharactersByUser(users.getUserById(fi.id))} type='dark'>
                                    Characters ({characters.getCharactersByUserId(fi.id as number).length})
                                </Button>

                                <div className='danger-zone'>
                                    <Button 
                                        type={`neutral`}
                                        inactive={getCanDestructivelyModify(fi.id)}
                                        clickEvent={() => {handleResetPassword(getUserById(fi.id as number))}}
                                    >
                                        Reset Password
                                    </Button>
                                    <DeleteButton 
                                        inactive={getCanDestructivelyModify(fi.id)}
                                        clickEvent={() => {handleDelete(getUserById(fi.id as number))}}
                                    />
                                </div>
                            </nav>
                        </div>
                    )) 
                }
            </div>
        </div>
    )
}