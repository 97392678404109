import { useState } from 'react';

import Form from './components/Form';
import uuid from 'react-uuid';

export interface IFormUnitOption {
    name?: string | number;
    value: string | number;
    disabled?: boolean;
}

export interface IFormUnit {
    title?: string;
    key: string;
    info?: string;
    value: string | number | boolean | File;
    inputType: string | 'string' | 'number' | 'date' | 'boolean' | 'text';
    options?: IFormUnitOption[];
    instructions?: boolean;
    labels?: boolean;
    textarea?: boolean;
    disabled?: boolean;
    targetFolder?: string;
}

export interface IParam {
    key: string;
    value: string | number | boolean;
}

export interface ISimpleForm {
    id?: number;
    title?: string;
    styleOptions?: {
        width?: 'center' | 'space-between'
        submitLabel?: string;
    }
    data: IFormUnit[];
    requestHandler: {
        target: string;
        params?: IParam[];
    };
    requestType: 'create' | 'update';
    responseHandler: (T: any) => void;
    responseObjectPath?: string,
    responseType: 'object' | 'array';
}

export interface ISimpleFormProps {
    formData: ISimpleForm;
    resetHandler?: () => any;
} 

export default function SimpleForm(props: ISimpleFormProps) {

    const [ key, setKey ] = useState(uuid());

    function reset() {
        props.resetHandler && props.resetHandler();
        setKey(uuid());
    }

    return (
        <Form 
            key={key}
            id={props.formData.id}
            title={props.formData.title}
            data={props.formData.data}
            styleOptions={props.formData.styleOptions}
            requestType={props.formData.requestType}
            requestHandler={props.formData.requestHandler}
            responseHandler={props.formData.responseHandler}
            responseObjectPath={props.formData.responseObjectPath}
            responseType={props.formData.responseType}
            reset={reset}
        />
    )
}