import { useState } from 'react';

export type TNotification = {
    type: 'confirmation' | 'warning' | 'error';
    content: string;
    title: string;
    timed?: boolean;
    timer?: number;
}

export interface IUseNotifications {
    get: TNotification[];
    lifeSupportList: number[];
    add: (T: TNotification) => void;
    kill: (T: number) => void;
    remove: (T: number) => void;
}

export default function useNotifications() {

    const [ notifications, setNotifications ] = useState<TNotification[] | []>([]);
    const [ lifeSupportList, setLifeSupportList ] = useState<number[]>([]);

    function add(message: TNotification) {
        const id = notifications.length;

        setNotifications(prev => [...prev, message]);
        setLifeSupportList(prev => [...prev, id])
    }

    function kill(indexToRemove: number) {
        setNotifications(prev => prev.filter((_, index) => index !== indexToRemove));
    }

    function unplugLifeSupport(id: number) {
        setLifeSupportList((prev) => prev.filter((i) => i !== id));
    }

    return { 
        get: notifications,
        lifeSupportList,
        add: add,
        kill,
        remove: unplugLifeSupport
    }
}