interface IDropDownItem {
    children?: any;
    value: string;
    clickEvent?: () => any
}

export default function DropdownItem(props: IDropDownItem) {

    const {
        children, 
        value, 
        clickEvent
    } = props;

    return(
        <option value={value} className='DropdownItem' onClick={clickEvent}>
            {children}
        </option>
    )
}