import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ILineGraph {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
  }[]
}

export default function LineGraph(props: ILineGraph) {
  const {
    labels,
    datasets
  } = props;

  const colors = [
    ['rgba(75, 192, 192)', 'rgba(75, 192, 192, 0.2)']
  ];

  const data = {
    labels: labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      fill: false,
      backgroundColor: colors[index][0],
      borderColor: colors[index][1],
      tension: 0.1
    })),
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className='LineGraph graph'>
      <Line options={options} data={data} />
    </div>
  );
}
